import { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import Home from "../pages/Home";
import SearchDetails from "../pages/SearchDetails";
import Info from "../pages/Info";
import ApplicationForm from "../pages/ApplicationForm";
import Dashboard from "../pages/Dashboard";
import University from "../pages/University";
import About from "../pages/About";
import Header from "./Header";
import Footer from "./Footer";
import ProtectedRoute from "./ProtectedRoute";
import { Route, Routes } from "react-router-dom";
import { Method } from "../config/method";

function DefaultRoutes() {
  const cookies = new Cookies(null, { path: "/" });
  const [isLoggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    if (cookies.get("jwt") !== undefined) {
      setLoggedIn(true);
    }
  }, []);

  const handleLogout = () => {
    cookies.remove('jwt', { path: '/' });
    // Method.RemoveLocalStorage('userdata')
    // Method.RemoveLocalStorage('cycle')
    Method.RemoveSessionStorage('userdata')
    Method.RemoveSessionStorage('cycle')
    setLoggedIn(false);
  };

  return (
    <>
      <Header isLoggedIn={isLoggedIn} onLogout={handleLogout} />
      <Routes>
        <Route path="/" element={<Home isLoggedIn={isLoggedIn} />} />
        <Route path="/searchdetails" element={<SearchDetails isLoggedIn={isLoggedIn}/>}  />
        <Route path="/info" element={<Info />}/>
        <Route path="/university" element={<University/>}/>
        <Route path="/about" element={<About/>}/>

        <Route element={<ProtectedRoute/>}>
          <Route path="/application" element={<ApplicationForm/>} />
          <Route path="/dashboard" element={<Dashboard/>}  />
        </Route>

        <Route path="*" element={<section>PAGE NOT FOUND</section>} />
      </Routes>
      <Footer />
    </>
  );
}

export default DefaultRoutes;
