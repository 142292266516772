import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { setData } from "../store/SearchSlice";
import { server } from "../config/config";
import { Method } from "../config/method";

const Country = ({ handleNext, handlePrevious, handleChange }) => {

  const reduxStore = Method.GetReduxStore()
  const dispatch = Method.GetReduxDispatch()

  const fetchData = async () => {
    const response = await Method.GetList(`${server}/api/country`)
    if(response.status === 200){
      const resData = response.data;
      const dropdown_data = Method.GetDropDownData(resData, 'country');
      dispatch(setData({ masterData : {countryList : dropdown_data }, masterDataList : {countryList : resData}}))
    }else {
      dispatch(setData({ masterData : {countryList : [] }, masterDataList : {countryList : []}}))
    }
  };
  
  // const selectedCountries = Method.ConvertJsonParse(Method.GetLocalStorage('cycle'))?.country
  const selectedCountries = Method.GetSessionStorage('cycle')?.country

  useEffect(() => {
    fetchData();
  }, []);
  
  return (
    // <div
    //   style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    // >
    //   <label
    //     style={{ marginBottom: "20px", fontSize: "18px", fontWeight: "bold" }}
    //   >
    //     Select Country:
    //   </label>

    // <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", marginBottom: "20px" }}>
    //   {reduxStore.masterData?.countryList?.map((o) => (
    //     <div key={o.value} style={{ marginBottom: "10px" }}>
    //       <input
    //         type="checkbox"
    //         id={o.value}
    //         name={o.value}
    //         checked={selectedCountries?.includes(o.value)}
    //         onChange={() => handleChange('country' , o.value, 'checkbox')}
    //         style={{ marginRight: "10px" }}
    //       />
    //       <label htmlFor={o.value}>{o.label}</label>
    //     </div>
    //   ))}
    // </div>

    //   <div
    //     style={{
    //       display: "flex",
    //       justifyContent: "space-between",
    //       width: "200px",
    //     }}
    //   >
    //     <button
    //       onClick={handleNext}
    //       style={{
    //         padding: "10px",
    //         backgroundColor: "#008CBA",
    //         color: "white",
    //         border: "none",
    //         borderRadius: "5px",
    //         cursor: "pointer",
    //       }}
    //     >
    //       Next
    //     </button>
    //   </div>
    // </div>

    <div class="education pd100 country">
      <div class="container">
          <div class="innerrow">
              <div class="heading">
                  <h2>Which country do you wish to pursue your education in?</h2>
                  {/* <a href="#" class="main-btn">Open To All</a> */}
              </div>
              <div class="list-country">
                {
                  reduxStore.masterData?.countryList?.map((o) => (
                    <div class="left">
                        <input type="checkbox"
                          onChange={() => handleChange('country' , o.value, 'checkbox')}
                          checked={selectedCountries?.includes(o.value)}
                        />
                        <img src="images/United-Kingdom.svg" alt=""/>
                        <h3>{o.label}</h3>
                        <span></span>
                    </div>
                  ))
                }
              </div>
              <div class="btn">
                  <button onClick={handleNext} class="main-btn">Next</button>
              </div>
          </div>
      </div>
    </div>
  );
};

export default Country;
