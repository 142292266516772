import { createSlice } from "@reduxjs/toolkit";

export const SearchSlice = createSlice({
  name: "search",
  initialState: {
    searchData : {
        country: [],
        subject: [],
        degree: '',
        intake: [],
    },
    masterData : {},
    masterDataList : {},
    // universityid: "",
    // universityname: "",
    currentstep : 0
  },
  reducers: {
    setData: (state, action) => {
      state.masterData = action.payload.masterData ? {...state.masterData,...action.payload.masterData} : state.masterData
      state.masterDataList = action.payload.masterDataList ? {...state.masterDataList,...action.payload.masterDataList} : state.masterDataList
    },
    updateSearchData: (state, action) => {
      state.searchData = action.payload ? {...action.payload} : state.searchData
    },
    // updateUniversityid: (state, action) => {
    //   state.universityid = action.payload ? action.payload : state.universityid
    // },
    // updateUniversityname: (state, action) => {
    //   state.universityname = action.payload ? action.payload : state.universityname
    // },
    updateCurrentStep: (state, action) => {
      state.currentstep = action.payload != undefined ? action.payload : state.currentstep
    },
  },
});

export const { setData, updateSearchData, updateCurrentStep } = SearchSlice.actions;

export default SearchSlice;
