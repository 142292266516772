import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import FacebookLogin from '@greatsumini/react-facebook-login';
import Button from './Button';

const Google_ClientId = process.env.REACT_APP_Google_ClientId;

const GoogleLoginPage = ( {handleLoginSuccess, handleLoginFailure} ) => {
    return (
        <div className="with-login-btn-wrap">
            <GoogleOAuthProvider clientId={Google_ClientId}>
                <GoogleLogin onSuccess={handleLoginSuccess} onFailure={handleLoginFailure}>
                Login with Google
                </GoogleLogin>
            </GoogleOAuthProvider>

            {/* <FacebookLogin 
                appId="YOUR_FACEBOOK_APP_ID"
                onSuccess={(response) => {
                    console.log('Login Success!', response);
                }}
                onFail={(error) => {
                    console.log('Login Failed!', error);
                }}
                onProfileSuccess={(response) => {
                    console.log('Get Profile Success!', response);
                }}
            /> */}
            <Button
                className="common facebook"
                label="Continue with Facebook"
            >
                <FacebookLogin 
                    appId="YOUR_FACEBOOK_APP_ID"
                    onSuccess={(response) => {
                        console.log('Login Success!', response);
                    }}
                    onFail={(error) => {
                        console.log('Login Failed!', error);
                    }}
                    onProfileSuccess={(response) => {
                        console.log('Get Profile Success!', response);
                    }}
                />
            </Button>
        </div>
    )
}

export default GoogleLoginPage