import React, { useState, useEffect } from "react";
import { plogo, plogo2, plogo3, plogo4, plogo5 } from "../images/index.js";
import { NavLink } from "react-router-dom";
import Cookies from "universal-cookie";
import Slider from "react-slick";
import axios from "axios";

import { server } from "../config/config";

const Partners = () => {
  const [univList, setUnivList] = useState([]);

  const fetchUnivData = async () => {
    try {
      const cookies = new Cookies(null, { path: "/" });
      const token = cookies.get("jwt");
      const config = {
        headers: {
          Authorization: token ? `Bearer ${token}` : null,
        },
      };
      const res = await axios.post(`${server}/api/university`, config);
      setUnivList(res.data.data);
    } catch (err) {
      console.error("Error getting data:", err);
    }
  };

  useEffect(() => {
    fetchUnivData();
  }, []);

  const partnersSettings = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <div className="partners">
      <div className="container">
        <div className="inner-roe pd100">
          <div className="viewheading">
            <h2>
              University <span className="par-bg">Partners</span>
            </h2>
            {/* <NavLink to="#" className="readmore">
              Show All
            </NavLink> */}
          </div>
          <div className="partners-logo">
            <Slider {...partnersSettings}>
              {univList?.map((item, i) => (
                <div>
                  <img
                    // src={server + "/uploads/" + item.image}
                    src={item.image}
                    alt={item.image}
                  />
                </div>
              ))}
              {/* <div>
                <img src={plogo} alt="" />
              </div>
              <div>
                <img src={plogo2} alt="" />
              </div>
              <div>
                <img src={plogo3} alt="" />
              </div>

              <div>
                <img src={plogo5} alt="" />
              </div>
              <div>
                <img src={plogo4} alt="" />
              </div>
              <div>
                <img src={plogo3} alt="" />
              </div> */}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;
