import React from "react";

const Button = ({
  label = "Button",
  type = "button",
  className = "",
  disabled = false,
  onClick = () => {},
  svg = null,
}) => {
  return (
    <button
      type={type}
      className={`${className}`}
      disabled={disabled}
      onClick={onClick}
    >
      {svg && (
        <span className="svg-container" dangerouslySetInnerHTML={{ __html: svg }} />
      )}
      {label}
    </button>
  );
};

export default Button;
