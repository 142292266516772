import React, { useEffect, useState } from "react";
import Select from "react-select";
import Button from "./Button";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  updateSearchData,
  setData,
} from "../store/SearchSlice";
import axios from "axios";
import { Method } from "../config/method";

import { server } from "../config/config";

const SearchBar = ({ isLoggedIn, fetchCourseData }) => {
  const location = useLocation();
  const pagename = location.pathname.split("/").pop();

  const dispatch = useDispatch();
  const reduxD = useSelector((state) => state.store);
  const { searchData, masterData } = reduxD;

  const navigate = useNavigate();

  const handleSearch = () => {
    if (pagename === "searchdetails") {
      fetchCourseData();
    } else {
      // const cycle = Method.ConvertJsonParse(Method.GetLocalStorage('cycle'))
      const cycle = Method.GetSessionStorage('cycle')
      if(cycle){
        const keys = ['country', 'degree', 'highesteducation', 'subject', 'englishtest', 'academictest']
        const index = keys.findIndex( key => {
          const value = cycle[key];
          return value === '' || value === undefined || (Array.isArray(value) && value.length === 0);
        })
        
        if(index >= 0){
          navigate(`/info`);
        }else{
          navigate(`/searchdetails`);
        }
      }else {
        navigate(`/info`);
      }
    }
  };

  const handleChange = (selectedItems, action, type) => {
    let temp = searchData;
    let values = searchData[action.name]

    // redux data manage for search
    let valueArr = selectedItems?.length ? selectedItems.map( v => v.value) : []
    if(type == 'multidropdown'){
      if(action.action === 'select-option'){
        values = valueArr
      }else if(action.action === 'remove-value'){
        values = valueArr
      }else if(action.action === 'clear'){
        values = []
      }
    } else if(type == 'dropdown'){
      // values = selectedItems?.value ? selectedItems.value : ''
      if(action.action === 'select-option'){
        values = selectedItems.value
      }else if(action.action === 'clear'){
        values = ''
      }
    }
        
    // session data manage for cycle
    const cycle = Method.GetSessionStorage('cycle')
    if(action.name == 'country' || action.name == 'subject' || action.name == 'degree'){
      if(type == 'multidropdown'){
        if(action.action === 'select-option' || action.action === 'remove-value'){
          cycle[action.name] = valueArr
        }else if(action.action === 'clear'){
          cycle[action.name] = []
        }
      }else{
        if(action.action === 'select-option'){
          cycle[action.name] = selectedItems.value
        }else if(action.action === 'clear'){
          cycle[action.name] = ''
        }
      }
      Method.SetSessionStorage('cycle',cycle)
    }
    
    temp = { ...temp, [action.name]: values };
    dispatch(updateSearchData(temp));
  };

  const getDropDownData = (data, field) => {
    let dropdown_data = [];
    if (data.length) {
      data.forEach((obj) => {
        // console.log(obj, field);
        dropdown_data.push({ value: obj._id, label: obj[field] });
      });
    }
    return dropdown_data;
  };
  

  const fetchSearchField = async () => {
    try {
      const formMasterData = [
        { api : '/api/country', dataStoreName : 'countryList', dataFeild : 'country'},
        { api : '/api/subject', dataStoreName : 'subjectList', dataFeild : 'subject'},
        { api : '/api/degree', dataStoreName : 'degreeList', dataFeild : 'degree'},
        { api : '/api/intake', dataStoreName : 'intakeList', dataFeild : 'intake'},
      ]

      formMasterData.map(async obj => {
        const response = await axios.post(`${server}${obj.api}`);
        if(response.status === 200){
          const resData = response.data.data;
          const dropdown_data = getDropDownData(resData, obj.dataFeild);
          dispatch(setData({ masterData : {[obj.dataStoreName] : dropdown_data }, masterDataList : {[obj.dataStoreName] : resData}}))
        }else {
          dispatch(setData({ masterData : {[obj.dataStoreName] : [] }, masterDataList : {[obj.dataStoreName] : []}}))
        }
      })

      // const cycle = Method.ConvertJsonParse(Method.GetLocalStorage('cycle'))
      const cycle = Method.GetSessionStorage('cycle')
      const updatedSearchData = { ...searchData };
        Object.keys(searchData).forEach( key => {
          if(cycle[key]){
            updatedSearchData[key] = cycle[key]
          }
        })
      dispatch(updateSearchData(updatedSearchData))

    } catch (err) {
      console.error("Error getting data:", err);
    }
  };

  useEffect(() => {
    const cycle = Method.GetSessionStorage('cycle')
    if(!cycle){
      Method.SetSessionStorage('cycle',{
        country : [],
        degree : '',
        highesteducation : '',
        highesteducationprtype : 0,
        highesteducationpr : '',
        subject : [],
        englishtest : '',
        englishtestpr : '',
        academictest : '',
        academictestpr : '',
      })
    }
    fetchSearchField();
  }, []);

  const DropdownValue = (valueArr, data = []) => {
    const value = data.filter( obj => valueArr?.includes(obj.value))
    return value
  }

  return (
    <div className="search-box">
      <div className="col">
        <label htmlFor="country">Country</label>
        <Select
          name="country"
          value={DropdownValue(searchData.country, masterData.countryList)}
          options={masterData?.countryList}
          onChange={(value, action) => handleChange(value, action, 'multidropdown')}
          isClearable={true}
          placeholder="Select"
          className=""
          isMulti="true"
        />
      </div>
      <div className="col">
        <label htmlFor="subject">Field of Interest</label>
        <Select
          name="subject"
          value={DropdownValue(searchData.subject, masterData.subjectList)}
          options={masterData?.subjectList}
          onChange={(value, action) => handleChange(value, action, 'multidropdown')}
          isClearable={true}
          placeholder="Select"
          className=""
          isMulti="true"
        />
      </div>
      <div className="col">
        <label htmlFor="degree">Degree</label>
        <Select
          name="degree"
          value={Method.GetObjectFromArray(masterData?.degreeList, 'value', searchData.degree)}
          options={masterData?.degreeList}
          onChange={(value, action) => handleChange(value, action, 'dropdown')}
          isClearable={true}
          placeholder="Select"
          className=""
        />
      </div>
      <div className="col">
        <label htmlFor="intake">Intake</label>
        <Select
          name="intake"
          value={DropdownValue(searchData.intake, masterData.intakeList)}
          options={masterData?.intakeList}
          onChange={(value, action) => handleChange(value, action, 'multidropdown')}
          isClearable={true}
          placeholder="Select"
          className=""
          isMulti="true"
        />
      </div>
      <div className="col">
        <Button label="Search" onClick={handleSearch} className="form-btn" />
      </div>
    </div>
  );
};

export default SearchBar;
