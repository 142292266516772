import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import axios from "axios";

import { server } from "../config/config";

const University = () => {
  const [univList, setUnivList] = useState([]);

  const fetchUnivData = async () => {
    try {
      const cookies = new Cookies(null, { path: "/" });
      const token = cookies.get("jwt");
      const config = {
        headers: {
          Authorization: token ? `Bearer ${token}` : null,
        },
      };
      const res = await axios.post(`${server}/api/university`, config);
      setUnivList(res.data.data);
    } catch (err) {
      console.error("Error getting data:", err);
    }
  };

  useEffect(() => {
    fetchUnivData();
  }, []);

  return (
    <div class="education pd100 country">
      <div class="container">
          <div class="innerrow">
              <div class="heading">
                  {/* <h2>Which country do you wish to pursue your education in?</h2> */}
              </div>
              <div class="list-country">
                {
                  univList?.map((o) => (
                    <div class="left">
                        <img src={o.image} alt=""/>
                        <div className="innerbox">
                          <h3>{o.university}</h3>
                          <h3>Founded In : {o.establishedyear}</h3>
                          <h3>Public/Private : {o.publicprivate}</h3>
                          <h3>Country : {o.country}</h3>
                          <span></span>
                       </div>
                    </div>
                  ))
                }
              </div>
          </div>
      </div>
    </div>
  );
};

export default University;
