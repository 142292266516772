import * as Yup from "yup";

export const documentsSchema = Yup.object({
  tenthmarksheet: Yup.string(),
  twelvemarksheet: Yup.string(),
  degreecertificate: Yup.string(),
  // sopletter: Yup.string()
  lorletter1: Yup.string(),
  lorletter2: Yup.string(),
  lorletter3: Yup.string(),
  ieltstoeflletter: Yup.string(),
  gregmatscorecard: Yup.string(),
  resume: Yup.string(),
});
