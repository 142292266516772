import React, { useEffect, useState } from "react";
import { server } from "../config/config";
import { Method } from "../config/method";
import { setData } from "../store/SearchSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "./Input";
import Button from "./Button";
import { Modal } from "rsuite";

const EnglishTest = ({ handleNext, handlePrevious, handleChange }) => {
  
  const [open, setOpen] = useState(false);
  const [selectid, setSelectid] = useState('')

  const reduxStore = Method.GetReduxStore()
  const dispatch = Method.GetReduxDispatch()

  const fetchData = async () => {
    const response = await Method.GetList(`${server}/api/englishtest`)
    if(response.status === 200){
      const resData = response.data;
      const dropdown_data = Method.GetDropDownData(resData, 'englishtest');
      dispatch(setData({ masterData : {englishTestList : dropdown_data }, masterDataList : {englishTestList : resData}}))
    }else {
      dispatch(setData({ masterData : {englishTestList : [] }, masterDataList : {englishTestList : []}}))
    }
  };

  // const cycleData = Method.ConvertJsonParse(Method.GetLocalStorage('cycle'))
  const cycleData = Method.GetSessionStorage('cycle')
  const selectedEnglishTest = cycleData.englishtest

  useEffect(() => {
    fetchData();
  }, []);

  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues : {
      englishtestpr : ''
    },
    validationSchema: Yup.object({
      englishtestpr: Yup.number()
        .required("Score/Bands is required")
    }),
    validate: (values) => {
      const errors = {};
      console.log(cycleData);
      if(cycleData.englishtest == '65c1f05b79de8d71c96c1402'){ //IELTS
        if (!["0", "0.5", "1", "1.5", "2", "2.5", "3", "3.5", "4", "4.5", "5", "5.5", "6", "6.5", "7", "7.5", "8", "8.5", "9"].includes(values.englishtestpr)) {
          // errors.englishtestpr = 'Invalid IELTS Score';
          errors.englishtestpr = 'Score/Bands is required';
        }
      }else if(cycleData.englishtest == '65c1f05b79de8d71c96c1403'){ // TOEFL
        if (parseInt(values.englishtestpr) < 0 || parseInt(values.englishtestpr) > 120) {
          // errors.englishtestpr = 'Score for TOEFL between 0 and 120';
          errors.englishtestpr = 'Score/Bands is required';
        }
      }else if(cycleData.englishtest == '65c1f05b79de8d71c96c1404'){ // PTE
        if (parseInt(values.englishtestpr) < 0 || parseInt(values.englishtestpr) > 90) {
          // errors.englishtestpr = 'Score for PTE between 0 and 90';
          errors.englishtestpr = 'Score/Bands is required';
        }
      }

      return errors
    },
    onSubmit: async (values, action) => {
      handleChange('englishtestpr', values.englishtestpr)
      setOpen(false)
      setFieldValue('englishtestpr', '')
    },
  });

  const handleNextStage = () => {
    if(cycleData.englishtestpr){
      handleNext()
    }else{
      setOpen(true)
    }
  }
  
  return (
    // <div
    //   style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    // >
    //   <label
    //     style={{ marginBottom: "20px", fontSize: "18px", fontWeight: "bold" }}
    //   >
    //     Select English Test:
    //   </label>

    //   <div
    //     style={{
    //       display: "flex",
    //       flexDirection: "column",
    //       alignItems: "flex-start",
    //       marginBottom: "20px",
    //     }}
    //   >
    //     {
    //       reduxStore.masterData?.englishTestList?.map((o) => (
    //         <div key={o.value} style={{ marginBottom: "10px" }}>
    //           <input
    //             type="radio"
    //             id={o.value}
    //             name="degree"
    //             value={o.value}
    //             style={{ marginRight: "10px" }}
    //             checked={selectedEnglishTest == o.value}
    //             onChange={() =>{ handleChange('englishtest', o.value, 'radio') }}
    //           />
    //           <label htmlFor={o.value}>{o.label}</label>
    //         </div>
    //       ))
    //     }

    //   </div>

    //   <div
    //     style={{
    //       display: "flex",
    //       justifyContent: "space-between",
    //       width: "200px",
    //     }}
    //   >
    //     <button
    //       onClick={handlePrevious}
    //       style={{
    //         padding: "10px",
    //         backgroundColor: "#4CAF50",
    //         color: "white",
    //         border: "none",
    //         borderRadius: "5px",
    //         cursor: "pointer",
    //       }}
    //     >
    //       Previous
    //     </button>
    //     <button
    //       onClick={handleNext}
    //       style={{
    //         padding: "10px",
    //         backgroundColor: "#008CBA",
    //         color: "white",
    //         border: "none",
    //         borderRadius: "5px",
    //         cursor: "pointer",
    //       }}
    //     >
    //       Next
    //     </button>
    //   </div>
    // </div>
    <div class="education pd100 country education-level">

            <Modal size='xs' open={open} onClose={()=>{setFieldValue('englishtestpr', ''); setOpen(false)}}>
              <Modal.Header>
                <Modal.Title style={{textAlign: 'center'}}>Enter your Score/Bands </Modal.Title>
              </Modal.Header>
                <form className="innertab" onSubmit={handleSubmit}>
              <Modal.Body>
                  <Input
                    style={{width : '100%'}}
                    type="number"
                    name={'englishtestpr'}
                    value={values.englishtestpr}
                    onChange={({target}) => {setFieldValue('englishtestpr', target.value)}}
                    placeholder={`Enter Score/Bands`}
                    className="fil form"
                    error={
                      errors.englishtestpr && touched.englishtestpr
                        ? errors.englishtestpr
                        : null
                    }
                  />
              </Modal.Body>
              <Modal.Footer>
                <div className="btns">
                  <Button label="Continue" type="submit" className="save" />
                </div>
              </Modal.Footer>
                </form>
            </Modal>

        <div class="container">
            <div class="innerrow">
                <div class="heading">
                    <h2>Which test you did?</h2>
                </div>
                <div class="list-country">
                {
                  reduxStore.masterData?.englishTestList?.map((o) => (
                    <div class="left">
                        <input 
                          type="radio" 
                          name="degree"
                          value={o.value}
                          checked={selectedEnglishTest == o.value}
                          onChange={() =>{ handleChange('englishtest', o.value, 'radio'); setSelectid(o.value); setOpen(true) }}
                        />
                        <h3>{o.label}</h3>
                        <span></span>
                    </div>
                  ))
                }
                </div>
                <div class="btn">
                    <button onClick={handlePrevious} class="main-btn">Previous</button>
                    <button onClick={handleNextStage} class="main-btn">Next</button>
                </div>
            </div>
        </div>
    </div>
  );
};

export default EnglishTest;
