import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import Button from "./Button";
import { useFormik } from "formik";
import { documentsSchema } from "../schemas/documentsSchema";
import axios from "axios";
import { toast } from "react-toastify";
import { Method } from "../config/method";

import { server } from "../config/config";

const Documents = ({ initialValues, updateUserData, handleNext, loading }) => {
  // const [initialValues, setInitialValues] = useState({
  //   tenthmarksheet: "",
  //   twelfthmarksheet: "",
  //   degcertificate: "",
  //   sopletter: "",
  //   lor: "",
  // });
  // const fetchUserEduData = async () => {
  //   const cookies = new Cookies(null, { path: "/" });
  //   const token = cookies.get("jwt");
  //   const config = {
  //     headers: {
  //       Authorization: token ? `Bearer ${token}` : null,
  //     },
  //   };
  //   const resp = await axios.get(`${server}/api/user/getedudocdata`, config);
  //   const EduDocData = resp.data.EduDocData;
  //   console.log(EduDocData);
  //   setInitialValues(Object.assign(initialValues, EduDocData));
  //   formik.setValues(initialValues);
  //   console.log(initialValues);
  // };

  // useEffect(() => {
  //   fetchUserEduData();
  // }, []);

  const uploadFile = async (file) => {
    const cookies = new Cookies(null, { path: "/" });
    const token = cookies.get("jwt");
    const config = {
      headers: {
        'Content-Type':'multipart/form-data',
        Authorization: token ? `Bearer ${token}` : null,
      },
    };
    const formData = new FormData();
    formData.append("file", file);
    const fileRes = await axios.post(`${server}/api/upload`, formData, config);
    return fileRes.data;
  };

  const handleFileUpload = async (values) => {
    // const fileKeys = [];

    for (const key in values) {
      if (values.hasOwnProperty(key) && values[key] instanceof File) {
        // const fileDetails = await uploadFile(values[key]);
        // values[key] = data.filename;
        // const data = fileDetails.data;
        let resFile = await Method.UploadFileToS3(values[key])
        console.log(resFile);

      }
    }

    // console.log(values);
  };

  let formik = useFormik({
    initialValues,
    validationSchema: documentsSchema,
    onSubmit: async (values, action) => {
      try {
        // const cookies = new Cookies(null, { path: "/" });
        // const token = cookies.get("jwt");
        // console.log(values);
        // const config = {
        //   headers: {
        //     "Content-type": "multipart/form-data",
        //     Authorization: token ? `Bearer ${token}` : null,
        //   },
        // };
        // const resp = await axios.post(
        //   `${server}/api/user/updateedudocdata`,
        //   values,
        //   config
        // );
        // const data = resp.data;
        // console.log(data);
        
        // await handleFileUpload(values);
        await updateUserData(values);
        handleNext();
      } catch (err) {
        console.log(err);
        toast(err.response.data);
      }
      action.resetForm();
    },
  });
  let {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = formik;

  const formFields = [
    {
      heading : 'Documents',
      fields : [
        { name : 'tenthmarksheet', label : '10th Marksheet', type : 'file'},
        { name : 'twelvemarksheet', label : '12th Marksheet', type : 'file'},
        { name : 'degreecertificate', label : 'Final Degree Certificate', type : 'file'},
      ]
    },
    {
      heading : 'Documents',
      fields : [
        { name : 'lorletter1', label : 'LOR1', type : 'file'},
        { name : 'lorletter2', label : 'LOR2', type : 'file'},
        { name : 'lorletter3', label : 'LOR3', type : 'file'},
      ]
    },
    {
      heading : 'Documents',
      fields : [
        {name: 'ieltstoeflletter', label: 'IELTS/ TeEFL PTE/ Duolingo/ MOI Letter', type : 'file'},
        {name: 'gregmatscorecard', label: 'GRE/ GMAT Scorecard', type : 'file'},
        {name: 'resume', label: 'Updated CV/ Resume', type : 'file'},
      ]
    }
  ]

  let fileName = (url) => {
    const fileName = url.split('/').pop();
    const decodedFileName = decodeURIComponent(fileName);
    return decodedFileName
  }

  return (
    <div className="tab-content" id="tab3">
      <form className="innertab" onSubmit={handleSubmit}>

      {
        formFields?.map( section => {
          return (
            <div className="cols">
              <div className="heading">
                <h3>{section.heading}</h3>
              </div>
              <div className="filed pdrl">
                {
                  section?.fields?.map( field => {
                    return (
                      <div className="fil form">
                        <label htmlFor={field.name}>
                          {field.label}
                        </label>
                        <input
                          type="file"
                          accept=".pdf"
                          name={field.name}
                          onChange={async ({ target }) =>{
                           
                            const file = target.files[0];
                            if (!file) return; // If no file is selected, do nothing

                            const extension = file.name.split('.').pop();
                            const validExtensions = ['pdf'];
                            if (!validExtensions.includes(extension.toLowerCase())) {
                              target.value = ''
                              toast.error('Invalid file type. Please select a PDF file.');
                              return;
                            }

                             let val = await Method.UploadFileToS3(target.files[0])
                            setFieldValue(field.name, val)
                          }}
                          onBlur={handleBlur}
                        />
                        {values[field.name]?.length ? <p onClick={() => window.open(values[field.name], '_blank')} style={{color: "green", fontSize: "12px ", cursor:'pointer'}}>{fileName(values[field.name])}</p>: null}
                        {errors[field.name] && touched[field.name] ? (
                          <p className="" style={{ color: "red", fontSize: "12px" }}>
                            {errors[field.name]}
                          </p>
                        ) : null}
                      </div>
                    )
                  })
                }
              </div>
            </div>
          )
        })
      }
        
        {/* <div className="cols">
          <div className="heading">
            <h3>Documents</h3>
          </div>
          <div className="filed pdrl">
            <div className="fil form">
              <label htmlFor="tenthmarksheet">10th Marksheet</label>
              <input
                type="file"
                accept=".pdf"
                name="tenthmarksheet"
                onChange={({ target }) =>
                  setFieldValue("tenthmarksheet", target.files[0])
                }
                onBlur={handleBlur}
              />
              {values.tenthmarksheet?.length ? <p style={{color: "green", fontSize: "12px "}}>{values.tenthmarksheet}</p>: null}
              {errors.tenthmarksheet && touched.tenthmarksheet ? (
                <p className="" style={{ color: "red", fontSize: "12px" }}>
                  {errors.tenthmarksheet}
                </p>
              ) : null}
            </div>
            <div className="fil form">
              <label htmlFor="twelvemarksheet">12th Marksheet</label>
              <input
                type="file"
                accept=".pdf"
                name="twelvemarksheet"
                onChange={({ target }) =>
                  setFieldValue("twelvemarksheet", target.files[0])
                }
                onBlur={handleBlur}
              />
              {values.twelvemarksheet?.length ? <p style={{color: "green", fontSize: "12px "}}>{values.twelvemarksheet}</p>: null}
              {errors.twelvemarksheet && touched.twelvemarksheet ? (
                <p className="" style={{ color: "red", fontSize: "12px" }}>
                  {errors.twelvemarksheet}
                </p>
              ) : null}
            </div>
          </div>
        </div>
        <div className="cols second-col">
          <div className="heading">
            <h3>Documents</h3>
          </div>
          <div className="filed pdrl">
            <div className="fil form">
              <label htmlFor="degreecertificate">
                Final Degree Certificate
              </label>
              <input
                type="file"
                accept=".pdf"
                name="degreecertificate"
                onChange={({ target }) =>
                  setFieldValue("degreecertificate", target.files[0])
                }
                onBlur={handleBlur}
              />
              {values.degreecertificate?.length ? <p style={{color: "green", fontSize: "12px "}}>{values.degreecertificate}</p>: null}
              {errors.degreecertificate && touched.degreecertificate ? (
                <p className="" style={{ color: "red", fontSize: "12px" }}>
                  {errors.degreecertificate}
                </p>
              ) : null}
            </div>
            <div className="fil form">
              <label htmlFor="sopletter">SOP</label>
              <input
                type="file"
                accept=".pdf"
                name="sopletter"
                onChange={({ target }) =>
                  setFieldValue("sopletter", target.files[0])
                }
                onBlur={handleBlur}
              />
              {values.sopletter?.length ? <p style={{color: "green", fontSize: "12px "}}>{values.sopletter}</p>: null}
              {errors.sopletter && touched.sopletter ? (
                <p className="" style={{ color: "red", fontSize: "12px" }}>
                  {errors.sopletter}
                </p>
              ) : null}
            </div>
          </div>
        </div>
        <div className="cols third-col">
          <div className="heading">
            <h3>Documents</h3>
          </div>
          <div className="filed pdrl">
            <div className="fil form">
              <label htmlFor="lorletter">LOR</label>
              <input
                type="file"
                accept=".pdf"
                name="lorletter"
                onChange={({ target }) =>
                  setFieldValue("lorletter", target.files[0])
                }
                onBlur={handleBlur}
              />
              {values.lorletter?.length ? <p style={{color: "green", fontSize: "12px "}}>{values.lorletter}</p>: null}
              {errors.lorletter && touched.lorletter ? (
                <p className="" style={{ color: "red", fontSize: "12px" }}>
                  {errors.lorletter}
                </p>
              ) : null}
            </div>
          </div>
        </div> */}
        <div className="btns">
          <Button label="Save" type="submit" className="save" disabled={loading} />
        </div>
      </form>
    </div>
  );
};

export default Documents;
