import React, { useState, useEffect } from "react";
import { heroBanner, about, image1, image2, man } from "../images/index.js";
import SearchBar from "../components/SearchBar";
import Partners from "../components/Partners";
import Benefits from "../components/Benefits.js";
import { NavLink } from "react-router-dom";
// import axios from "axios";

// import { server } from "../config/config";

const Home = ({ isLoggedIn }) => {
  // const [userList, setuserList] = useState([]);
  // const [ind, setInd] = useState(0);

  // const fetchUserData = async () => {
  //   try {
  //     const res = await axios.post(`${server}/api/user`);
  //     setuserList(res.data.userList);
  //   } catch (err) {
  //     console.error("Error getting data:", err);
  //   }
  // };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <section>
      <div className="herobanner">
        <div className="container">
          <div className="innerbanner">
            <div className="left">
              <h1>
                Simplify the University
                <span className="application"> Application</span> Process using AI
              </h1>
              <p>
                Education allows students to easily apply to multiple
                universities through our unified platform for
                <NavLink to="#"> free</NavLink> . Save time and reduce stress!
              </p>
            </div>
            <div className="right">
              <img src={heroBanner} alt="heroBanner" />
            </div>
          </div>
          <SearchBar isLoggedIn={isLoggedIn}/>
        </div>
      </div>

      <Partners />

      <div className="about pd100">
        <div className="container">
          <div className="inner-row">
            <div className="left">
              <img src={about} alt="" />
            </div>
            <div className="right">
              <h2>
                CourseBuddy simplifies the application process by allowing you
                to complete one{" "}
                <span className="profile">integrated profile </span>
                for all your universities. No more repeating the same
                information over and over!
              </h2>
              <p>
                Our Al tools provide recommendations to make your applications
                stronger. Built-in proof reading, plagiarism checks, and support
                ensure your applications shine
              </p>
              <NavLink to={isLoggedIn ? "/info" : "/signup"} className="main-btn">
                Get Started
              </NavLink>
            </div>
          </div>
        </div>
      </div>

      <Benefits />

      <div className="one-profile pd100">
        <div className="container">
          <div className="inner-row">
            <h2 className="h3">
              <span></span> One profile for all your university applications,
              get Al recommendations to strengthen your application, utilize
              tools for proofreading/plagiarism checks/feedback, and track all
              your applications in one place.<span></span>
            </h2>
            <p className="name">Knut Olson</p>
            <p className="position">
              Senior Vice President, Thrivent Financial
            </p>
          </div>
        </div>
      </div>

      <div className="vision pd100">
        <div className="container">
          <div className="inner-row">
            <div className="list">
              <div className="left">
                <h2>
                  Our <span className="mission">Vision</span>
                </h2>
                <p>
                  To simplify and optimize the global university application
                  process through an integrated platform connecting students and
                  admission teams.
                </p>
              </div>
              <div className="left">
                <img src={image1} alt="" />
              </div>
            </div>
            <div className="list">
              <div className="left">
                <h2>
                  Our <span className="mission-2">Mission</span>
                </h2>
                <p>
                  We empower students and universities to manage applications
                  smarter by uniting them on one intelligent platform.
                </p>
              </div>
              <div className="left">
                <img src={image2} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div
        className="feedback"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div style={{ flex: 1, marginRight: "5%" }}>
          <h4>Students Feedback</h4>
          <img
            src={server + "/uploads/" + userList[0]?.imagename}
            alt="Profile"
          />
          <h6>{userList[ind]?.userName}</h6>
          <p>{userList[ind]?.feedback}</p>
          {ind > 0 && (
            <BsFillArrowLeftCircleFill onClick={() => setInd(ind - 1)} />
          )}
          {ind < userList?.length - 1 && (
            <BsFillArrowRightCircleFill onClick={() => setInd(ind + 1)} />
          )}
          <Button label="Start Now" onClick={handleStartButtonClick} />
        </div>
        <div style={{ flex: 1 }}>
          <img src="" alt="" />
        </div>
      </div> */}

      <div className="simplify">
        <div className="container">
          <div className="top">
            <h2>
              Simplify Your Study <span>Abroad Journey</span>
            </h2>
            <p>
              Our platform streamlines the university admissions process, making
              it easy for you to apply to multiple universities with just one
              profile.
            </p>
          </div>
          <div className="list-type">
            <div className="list">
              <h3>Save Time and Effort</h3>
              <ul>
                <li>Unified profile auto-populates application forms</li>
                <li>
                  Apply to multiple universities through one integrated system
                </li>
                <li>Save time and avoid repetitive data entry</li>
              </ul>
              <span>
                <NavLink to="#" className="read-more-btn">
                  Read More
                </NavLink>
              </span>
            </div>
            <div className="list">
              <h3>AI-Powered Application Optimization</h3>
              <ul>
                <li>Maximizes your application profile impact</li>
                <li>Suggests best-fit universities based on your profile</li>
                <li>
                  Receive data-driven insights to boost application success
                </li>
              </ul>
              <span>
                <NavLink className="read-more-btn">Read More</NavLink>
              </span>
            </div>
            <div className="list">
              <h3>Real-time Status Updates</h3>
              <ul>
                <li>Manage all applications in one place</li>
                <li>Receive instant updates on submission status</li>
                <li>Stay informed throughout the application process</li>
              </ul>
              <span>
                <NavLink className="read-more-btn">Read More</NavLink>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="cta pd100">
        <div className="container">
          <div className="inner-cta">
            <h2>
              Connecting Every Student To A{" "}
              <span className="word">World Of University</span>
            </h2>
            <p>
            While there are numerous diverse passages available for exploration, many have undergone alterations in their presentation. Some have been infused with humor, lending them a unique charm and wit.
            </p>
            <NavLink to={isLoggedIn ? "/info" : "/signup"} className="main-btn">
              Start Now
            </NavLink>
            <img src={man} alt="" className="ctaimage" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
