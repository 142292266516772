import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./css/style.scss";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-number-input/style.css'
import 'rsuite/dist/rsuite.min.css';
import {Store} from "./store/Store.js";
import { Provider } from "react-redux";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={Store}>
    <App />
    <ToastContainer/>
  </Provider>
);
