import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import {
  UserProfilePngImage,
  instagram,
  notification,
} from "../images/index.js";
import PersonalDetails from "../components/PersonalDetails";
import EducationalDetails from "../components/EducationalDetails";
import Documents from "../components/Documents";
import UniversitySpecific from "../components/UniversitySpecific";
import { format } from "date-fns";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { Method } from "../config/method.js";

import { server } from "../config/config";

const ApplicationForm = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [temp, setTemp] = useState(0);
  const [loading, setLoading] = useState(false)

  const universityname = Method.GetSessionStorage('course')?.university;

  const [initialValues, setInitialValues] = useState({
    email: "",
    firstname: "",
    lastname: "",
    fullname: "",
    dob: null,
    placeofbirth: "",
    contactno: "",
    profilepic: "",
    houseno: "",
    cityid: "",
    city: "",
    countryid: "",
    country: "",
    stateid : "",
    state : "",
    pincode: "",
    address: "",
    optional: "",
    tenthboard: "",
    tenthyear: "",
    tenthpr: "",
    twelveboard: "",
    twelveyear: "",
    twelvepr: "",
    graduateuniversity: "",
    graduateyear: "",
    graduatepr: "",
    tenthmarksheet: "",
    twelvemarksheet: "",
    degreecertificate: "",
    lorletter1: "",
    lorletter2: "",
    lorletter3: "",
    ieltstoeflletter: "",
    gregmatscorecard: "",
    resume: "",
  });

  const fetchUserData = async () => {
    const cookies = new Cookies(null, { path: "/" });
    const token = cookies.get("jwt");
    const config = {
      headers: {
        Authorization: token ? `Bearer ${token}` : null,
      },
    };
    let filter = {}
    // filter._id = Method.ConvertJsonParse(Method.GetLocalStorage('userdata'))?._id || "";
    filter._id = Method.GetSessionStorage('userdata')?._id
    const resp = await axios.post(
      `${server}/api/user`,
      { filter },
      config
    );
    const userData = resp.data.data[0];
    // console.log(userData);
    if (userData.dob !== null) {
      const dateObject = new Date(userData.dob);
      userData.dob = format(dateObject, "yyyy-MM-dd");
    }
    setInitialValues(Object.assign(initialValues, userData));
    setTemp(temp+1)
    // console.log(initialValues);
  };

  const updateUserData = async (updateData) => {
    setLoading(true)
    try {
      const cookies = new Cookies(null, { path: "/" });
      const token = cookies.get("jwt");
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: token ? `Bearer ${token}` : null,
        },
      };
      const resp = await axios.put(
        `${server}/api/user/update`,
        updateData,
        config
      );
      const data = resp.data;
      if(data.status === 200){
        toast.success(data.message);
        fetchUserData();
        setLoading(false)
      }
    } catch (e) {
    setLoading(false)
    console.log(e);
  }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    // fetchUserData();
  }, []);

  const handleNext = async () => {
    setTimeout(() => {
      setActiveStep(activeStep + 1);
      window.scrollTo(0, 0);
    }, 1000);
    await handleUpdateApply()
    
  };

  const handleClick = (step) => {
    setActiveStep(step);
  };

  const uploadFile = async (file) => {
    const cookies = new Cookies(null, { path: "/" });
    const token = cookies.get("jwt");
    const config = {
      headers: {
        'Content-Type':'multipart/form-data',
        Authorization: token ? `Bearer ${token}` : null,
      },
    };
    const formData = new FormData();
    formData.append("file", file);
    const fileRes = await axios.post(`${server}/api/upload`, formData, config);
    return fileRes.data;
  };

  const handleImageFileChange = async (target) => {
    initialValues.profilepic = await Method.UploadFileToS3(target.files[0])
    updateUserData(initialValues);
  };

  const handleUpdateApply = async (flag) => {
    try {
      const course = Method.GetSessionStorage('course')
      const cookies = new Cookies(null, { path: "/" });
      const token = cookies.get("jwt");
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: token ? `Bearer ${token}` : null,
        },
      };

      const payload = {
        courseid : course._id, 
        course : course.course, 
        universityid : course.universityid, 
        university : course.university
      }
      const resp = await axios.post(`${server}/api/user/course/applied`, payload, config);
      const data = resp.data;
      if(data.status === 200 && flag){
        toast.success(data.message);
      }
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <section>
      <div className="hero-profile">
        <div className="container">
          <div className="inner">
            <div className="profile-section">
              <div className="imageprofile">
                {initialValues.profilepic?.length ? (
                  <img
                    src={initialValues.profilepic}
                    alt={initialValues.profilepic}
                    className="userimage"
                  />
                ) : (
                  <img src={UserProfilePngImage} alt="" />
                )}
                <label htmlFor="fileInput">
                  <img src={instagram} alt="" />
                </label>
                <input
                  type="file"
                  accept="image/*"
                  id="fileInput"
                  style={{ display: "none" }}
                  onChange={({ target }) => handleImageFileChange(target)}
                />
              </div>
            </div>
            {initialValues && initialValues?.firstname && (
              <div className="names">
                <h2>{initialValues?.firstname}</h2>
                <h3>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="14"
                    viewBox="0 0 12 14"
                    fill="none"
                  >
                    <path
                      d="M6.00007 7.62502C7.03574 7.62502 7.87515 6.78562 7.87515 5.74995C7.87515 4.71429 7.03574 3.87488 6.00007 3.87488C4.96441 3.87488 4.125 4.71429 4.125 5.74995C4.125 6.78562 4.96441 7.62502 6.00007 7.62502Z"
                      stroke="#009DA5"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.0002 13.2502C8.50029 10.7501 11.0004 8.51131 11.0004 5.74995C11.0004 2.98859 8.76155 0.749756 6.0002 0.749756C3.23884 0.749756 1 2.98859 1 5.74995C1 8.51131 3.5001 10.7501 6.0002 13.2502Z"
                      stroke="#009DA5"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  {initialValues?.city}, {initialValues?.country}
                </h3>
              </div>
            )}
            {/* <div className="notifition">
              <img src={notification} alt="" />
              <span>0</span>
            </div> */}
            <div className="notifition">
              <h3>{universityname}</h3>
            </div>
          </div>
        </div>
      </div>

      <div className="tabsection pd100">
        <div className="tabs">
          <div className="container">
            <ul id="tabs-nav">
              <li
                className={activeStep === 0 ? "active" : ""}
                onClick={() => handleClick(0)}
              >
                <NavLink>Personal Details</NavLink>
              </li>
              <li
                className={activeStep === 1 ? "active" : ""}
                onClick={() => handleClick(1)}
              >
                <NavLink>Educational Details</NavLink>
              </li>
              <li
                className={activeStep === 2 ? "active" : ""}
                onClick={() => handleClick(2)}
              >
                <NavLink>Documents</NavLink>
              </li>
              <li
                className={activeStep === 3 ? "active" : ""}
                onClick={() => handleClick(3)}
              >
                <NavLink>University Specific Questions</NavLink>
              </li>
            </ul>
          </div>
        </div>

        <div className="container">
          <div id="tabs-content">
            {
              activeStep === 0 ?
                <PersonalDetails
                  initialValues={initialValues}
                  updateUserData={updateUserData}
                  handleNext={handleNext}
                  fetchUserData={fetchUserData}
                  loading={loading}
                />
              : activeStep === 1 ?
                <EducationalDetails
                  initialValues={initialValues}
                  updateUserData={updateUserData}
                  handleNext={handleNext}
                  loading={loading}
                />
              : activeStep === 2 ?
                <Documents
                  initialValues={initialValues}
                  updateUserData={updateUserData}
                  handleNext={handleNext}
                  loading={loading}
                />
              : activeStep === 3 ?
                <UniversitySpecific 
                  handleUpdateApply={handleUpdateApply}
                />
              : null
            }
          </div>
        </div>
      </div>
    </section>
  );
};

export default ApplicationForm;
