import React, { useState, useEffect } from "react";
import { formPageLogo } from "../images/index.js";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import Input from "../components/Input";
import { useFormik } from "formik";
import { loginSchema } from "../schemas/loginSchema";
import { NavLink } from "react-router-dom";
import Slider from "react-slick";
import axios from "axios";
import GoogleLoginPage from "../components/GoogleLogin.js";
import { toast } from "react-toastify";
import { Method } from "../config/method.js";

import { server } from "../config/config";

const Login = ({ setLoggedIn }) => {
  const [initialValues, setInitialValues] = useState({
    email: "",
    password: "",
    remember: false,
  });

  const navigate = useNavigate();
  const cookies = new Cookies(null, { path: "/" });

  useEffect(() => {
    if (cookies.get("jwt") !== undefined) {
      navigate("/", { replace: true });
    }
  }, []);

  const handleLoginSuccess = async (response) => {
    let res = {
      credential:
        "eyJhbGciOiJSUzI1NiIsImtpZCI6Ijg1ZTU1MTA3NDY2YjdlMjk4MzYxOTljNThjNzU4MWY1YjkyM2JlNDQiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20iLCJhenAiOiIyMzkwNjcwNjA1MjQtNDl2MWwzcXFlNGFidnVlNTE2YTlqbmYzMWoxMHQ4aDAuYXBwcy5nb29nbGV1c2VyY29udGVudC5jb20iLCJhdWQiOiIyMzkwNjcwNjA1MjQtNDl2MWwzcXFlNGFidnVlNTE2YTlqbmYzMWoxMHQ4aDAuYXBwcy5nb29nbGV1c2VyY29udGVudC5jb20iLCJzdWIiOiIxMTI3MTExOTU1NjMzMDY0OTMwMDkiLCJlbWFpbCI6ImhhcnNoZW5nLndlYmZvcmVzdEBnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwibmJmIjoxNzA2NzAxMTUxLCJuYW1lIjoiSGFyc2ggR2FqZXJhIiwicGljdHVyZSI6Imh0dHBzOi8vbGgzLmdvb2dsZXVzZXJjb250ZW50LmNvbS9hL0FDZzhvY0lDVlNCUmd0R1NXZ1l4d3hBd3oyRW1hWmdlNXFLbzEyM3lNbnplb1Jhdj1zOTYtYyIsImdpdmVuX25hbWUiOiJIYXJzaCIsImZhbWlseV9uYW1lIjoiR2FqZXJhIiwibG9jYWxlIjoiZW4iLCJpYXQiOjE3MDY3MDE0NTEsImV4cCI6MTcwNjcwNTA1MSwianRpIjoiNTdjYTA3OGE1Yjc2MGQyNzE1MDNlYWY2ZGZkNThjOWI4ODczODM3MyJ9.gl1tAfrUOgcjgRpcooPQCz9L7Vx7Fercc0Tiu81zLB8R4chMJorkuB8TJWsH2tT8z8Z_ysfvxb5q6n4XYB5XLs3eiGXU64g9ssgDAThnHU-F-M07oeB-ulG7YILhk7soae8TwLtJ16r5Gq5AR3mZphKtPwaA9YrQmvzp7pidq0W5K2J735nN4gXh8wNyH_3D2QU08703G8q5FXeSS_l0nVGgyEiovia0beobbLo92M7VsrbXNbPAs_9zh2StGkeqf8y2ev1-6b042T7pc3cdlipXsK1ksHTLjgqN92SE9c58u9TlE5AQ_Z_3rmG5_d6KzgYx06lRbcLDdzzlhwuzVw",
      clientId:
        "239067060524-49v1l3qqe4abvue516a9jnf31j10t8h0.apps.googleusercontent.com",
      select_by: "btn",
    };
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      const resp = await axios.post(
        `${server}/api/user/login`,
        { ...response, googlelogin: true },
        config
      );
      const data = resp.data;
      // store and add cycle data
      if(data.user.cycle){
        // Method.SetLocalStorage('cycle', Method.ConvertJsonString(data.user.cycle))
        Method.SetSessionStorage('cycle', data.user.cycle)
      }else{
        // add cycle data to login user, if don't have on database
        // const cycle = Method.ConvertJsonParse(Method.GetLocalStorage('cycle'))
        const cycle = Method.GetSessionStorage('cycle')
        if(cycle){
          const updateData = {...data.user, cycle}
          const config = {
            headers: {
              "Content-type": "application/json",
              Authorization: `Bearer ${data.jwt}`,
            },
          };
          await axios.put(`${server}/api/user/update`, updateData, config);
        }
      }
      cookies.set("jwt", data.jwt, { maxAge: data.maxAge });
      // localStorage.setItem("userdata", Method.ConvertJsonString(data.user));
      Method.SetSessionStorage('userdata', data.user)
      toast.success(data.message);
      setLoggedIn(true);
      navigate(`/`);
    } catch (err) {
      // console.log(err.response.data.message);
      toast.error(err.response.data.message);
    }
  };
  const handleLoginFailure = (response) => {
    console.log(response);
  };

  let formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, action) => {
      try {
        const config = {
          headers: {
            "Content-type": "application/json",
          },
        };
        const resp = await axios.post(
          `${server}/api/user/login`,
          values,
          config
        );
        const data = resp.data;
        // store and add cycle data
        if(data.user.cycle){
          // Method.SetLocalStorage('cycle', Method.ConvertJsonString(data.user.cycle))
          Method.SetSessionStorage('cycle', data.user.cycle)
        }else{
          // add cycle data to login user, if don't have on database
          // const cycle = Method.ConvertJsonParse(Method.GetLocalStorage('cycle'))
          const cycle = Method.GetSessionStorage('cycle')
          if(cycle){
            const updateData = {...data.user, cycle}
            const config = {
              headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${data.jwt}`,
              },
            };
            await axios.put(`${server}/api/user/update`, updateData, config);
          }
        }
        cookies.set("jwt", data.jwt, { maxAge: data.maxAge });
        // localStorage.setItem("userdata", Method.ConvertJsonString(data.user));
        Method.SetSessionStorage("userdata", data.user);
        toast.success(data.message);
        setLoggedIn(true);
        navigate(`/`);
        action.resetForm();
      } catch (err) {
        // console.log(err.response.data.message);
        toast.error(err.response.data.message);
      }
    },
  });

  let { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    formik;

  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="login-page common-form-page">
      <div className="wrap">
        <div className="left-right-wrap">
          <div className="left-wrap">
            <div className="logo-wrap">
              <NavLink to="/" exact>
                <img src={formPageLogo} className="img-fluid" alt="logo" />
              </NavLink>
            </div>
            <div className="bottom-wrap">
              <Slider {...sliderSettings} className="profile-slider-wrap" autoplay={true} autoplaySpeed={3000}>
              <div className="content-wrap">
                    <h2>Live Tracking</h2>
                    <p>
                      Create one profile that auto-populates all application
                      forms.
                    </p>
                  </div>

                  <div className="content-wrap">
                    <h2>Simplify Your University Applications</h2>
                    <p>
                      One profile, multiple universities - Say goodbye to repetitive forms and embrace a streamlined application process.
                    </p>
                  </div>

                  <div className="content-wrap">
                    <h2>AI-Powered Support and Recommendations</h2>
                    <p>
                      Let our intelligent AI assist you with personalized recommendations, proofreading, and support throughout your university application journey.
                    </p>
                  </div>
              </Slider>
            </div>
            <div className="copyright-wrap">
              <p>Copyright &copy; 2024 CourseBuddy</p>
            </div>
          </div>

          <div className="right-wrap">
            <div className="wrap">
              <h3>Welcome back!</h3>
              <p>Enter your Credentials to access your account</p>
              <form id="user-login" onSubmit={handleSubmit}>
                <Input
                  label="Email Address *"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter your email"
                  inputclassName="required"
                  className="item email"
                  error={errors.email && touched.email ? errors.email : null}
                />

                <Input
                  label="Password *"
                  type="password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Your Password"
                  inputclassName="required"
                  className="item pass"
                  error={
                    errors.password && touched.password ? errors.password : null
                  }
                />

                <div className="checkbox-forgot">
                  <div className="checkbox-text-wrap">
                    <input
                      type="checkbox"
                      name="remember"
                      onChange={handleChange}
                    />
                    <span>Remember for 30 days</span>
                  </div>
                  <div className="forgot-wrap">
                    <NavLink to="https://sft.ch/forgot-password">
                      Forgot your password?
                    </NavLink>
                  </div>
                </div>
                <Input
                  value="Log in"
                  type="submit"
                  inputclassName="btn"
                  className="btn-wrap"
                />

                <div className="or-wrap">
                  <span className="left"></span>
                  <span className="center">Or</span>
                  <span className="right"></span>
                </div>

                {/* <div className="with-login-btn-wrap">
                  <GoogleOAuthProvider clientId={Google_ClientId}>
                    <GoogleLogin
                      onSuccess={handleLoginSuccess}
                      onFailure={handleLoginFailure}
                    >
                      Login with Google
                    </GoogleLogin>
                  </GoogleOAuthProvider>
                  <AppleLogin
                    clientId={Google_ClientId}
                    redirectURI="YOUR_REDIRECT_URI"
                    onSuccess={handleLoginSuccess}
                    onFailure={handleLoginFailure}
                  />
                </div> */}
                <GoogleLoginPage
                  handleLoginSuccess={handleLoginSuccess}
                  handleLoginFailure={handleLoginFailure}
                />

                <div className="dont-have-account-wrap">
                  <h4>
                    Don’t have an account?
                    <NavLink to="../signup">Sign up</NavLink>
                  </h4>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
