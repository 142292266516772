import React, { useEffect, useState } from "react";
import { server } from "../config/config";
import { Method } from "../config/method";
import { setData } from "../store/SearchSlice";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "./Input";
import Button from "./Button";
import { Modal } from "rsuite";

const AcademicTest = ({ handleNext, handlePrevious, handleChange }) => {

  const [open, setOpen] = useState(false);

  const cookies = new Cookies(null, { path: "/" });
  const reduxStore = Method.GetReduxStore()
  const dispatch = Method.GetReduxDispatch()
  const navigate = useNavigate();

  const fetchData = async () => {
    const response = await Method.GetList(`${server}/api/academictest`)
    if(response.status === 200){
      const resData = response.data;
      const dropdown_data = Method.GetDropDownData(resData, 'academictest');
      dispatch(setData({ masterData : {academicTestList : dropdown_data }, masterDataList : {academicTestList : resData}}))
    }else {
      dispatch(setData({ masterData : {academicTestList : [] }, masterDataList : {academicTestList : []}}))
    }
  };

  // const cycleData = Method.ConvertJsonParse(Method.GetLocalStorage('cycle'))
  const cycleData = Method.GetSessionStorage('cycle')
  const selectedDegree = cycleData.academictest

  useEffect(() => {
    fetchData();
  }, []);
  
  const handleSubmitForm = async () => {
    // const userData = Method.ConvertJsonParse(Method.GetLocalStorage('userdata'));
    const userData = Method.GetSessionStorage('userdata')
    const token = cookies.get("jwt");
    if(userData && token){
      // const cycle = Method.ConvertJsonParse(Method.GetLocalStorage('cycle'));
      const cycle = Method.GetSessionStorage('cycle')
      const updateData = {...userData, cycle}
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: token ? `Bearer ${token}` : null,
        },
      };
      const resp = await axios.put(
        `${server}/api/user/update`,
        updateData,
        config
      );
      const data = resp.data;
      if(data.status === 200){
        toast.success(data.message);
        // Method.SetLocalStorage('cycle', Method.ConvertJsonString(data.data.cycle))
        Method.SetSessionStorage('cycle', data.data.cycle)
        navigate(`/searchdetails`)
      }
    }else{
      navigate(`/searchdetails`)
    }

  }


  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues : {
      academictestpr : ''
    },
    validationSchema: Yup.object({
      academictestpr: Yup.number()
        .required("Percentage is required")
        .min(0, "Percentage must be between 0 and 100")
        .max(100, "Percentage must be between 0 and 100"),
    }),
    onSubmit: async (values, action) => {
      handleChange('academictestpr', values.academictestpr)
      setOpen(false)
    },
  });

  const handleNextStage = () => {
    if(cycleData.academictestpr || cycleData.academictest == '65caf6d7b240c09d23d92b64'){
      handleSubmitForm()
    }else{
      setOpen(true)
    }
  }
  
  return (
    // <div
    //   style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    // >
    //   <label
    //     style={{ marginBottom: "20px", fontSize: "18px", fontWeight: "bold" }}
    //   >
    //     Select Academic test:
    //   </label>

    //   <div
    //     style={{
    //       display: "flex",
    //       flexDirection: "column",
    //       alignItems: "flex-start",
    //       marginBottom: "20px",
    //     }}
    //   >
    //    {
    //       reduxStore.masterData?.academicTestList?.map((o) => (
    //         <div key={o.value} style={{ marginBottom: "10px" }}>
    //           <input
    //             type="radio"
    //             id={o.value}
    //             name="academictest"
    //             value={o.value}
    //             style={{ marginRight: "10px" }}
    //             checked={selectedDegree == o.value}
    //             onChange={() =>{ handleChange('academictest', o.value, 'radio') }}
    //           />
    //           <label htmlFor={o.value}>{o.label}</label>
    //         </div>
    //       ))
    //     }

    //   </div>

    //   <div
    //     style={{
    //       display: "flex",
    //       justifyContent: "space-between",
    //       width: "200px",
    //     }}
    //   >
    //     <button
    //       onClick={handlePrevious}
    //       style={{
    //         padding: "10px",
    //         backgroundColor: "#4CAF50",
    //         color: "white",
    //         border: "none",
    //         borderRadius: "5px",
    //         cursor: "pointer",
    //       }}
    //     >
    //       Previous
    //     </button>
    //     <button
    //       style={{
    //         padding: "10px",
    //         backgroundColor: "#008CBA",
    //         color: "white",
    //         border: "none",
    //         borderRadius: "5px",
    //         cursor: "pointer",
    //       }}
    //       onClick={handleSubmit}
    //     >
    //       Submit
    //     </button>
    //   </div>
    // </div>
    <div class="education pd100 country education-level">

            <Modal size='xs' open={open} onClose={()=>{setFieldValue('academictestpr',''); setOpen(false)}}>
              <Modal.Header>
                <Modal.Title style={{textAlign: 'center'}}>Enter your Percentage</Modal.Title>
              </Modal.Header>
                <form className="innertab" onSubmit={handleSubmit}>
              <Modal.Body>
                  <Input
                    style={{width : '100%'}}
                    type="number"
                    name={'academictestpr'}
                    value={values.academictestpr}
                    onChange={({target}) => {setFieldValue('academictestpr', target.value);}}
                    placeholder={`Enter Percentage/CGPA`}
                    className="fil form"
                    error={
                      errors.academictestpr && touched.academictestpr
                        ? errors.academictestpr
                        : null
                    }
                  />
              </Modal.Body>
              <Modal.Footer>
                <div className="btns">
                  <Button label="Continue" type="submit" className="save" />
                </div>
              </Modal.Footer>
                </form>
            </Modal>

        <div class="container">
            <div class="innerrow">
                <div class="heading">
                    <h2>Which academic exam had you completed?</h2>
                </div>
                <div class="list-country">
                {
                  reduxStore.masterData?.academicTestList?.map((o) => (
                    <div class="left">
                        <input 
                          type="radio" 
                          name="academictest"
                          value={o.value}
                          checked={selectedDegree == o.value}
                          onChange={() =>{ 
                            handleChange('academictest', o.value, 'radio'); 
                            if (o.value != '65caf6d7b240c09d23d92b64') {
                              setOpen(true);
                            }
                          }}
                        />
                        <h3>{o.label}</h3>
                        <span></span>
                    </div>
                  ))
                }
                </div>
                <div class="btn">
                    <button onClick={handlePrevious} class="main-btn">Previous</button>
                    <button onClick={handleNextStage} class="main-btn">Submit</button>
                </div>
            </div>
        </div>
    </div>
  );
};

export default AcademicTest;
