import * as Yup from "yup";

export const personaldetailsSchema = Yup.object({
  firstname: Yup.string().required("first name is required"),
  lastname: Yup.string().required("last name is required"),
  dob: Yup.date()
    .nullable()
    .required("Date of Birth is required")
    .max(
      new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
      "You must be at least 18 years old"
    ),
  placeofbirth: Yup.string().required("place of birth is required"),
  email: Yup.string().email("invalid email").required("email is required"),
  // contactno: Yup.string()
  //   .matches(/^[0-9]+$/, "invalid phone number")
  //   .required("contact number is required"),
  contactno: Yup.string()
      .required('Mobile number is required')
      .matches(/^\+[1-9]\d{10,14}$/, 'Invalid phone number'),
  houseno: Yup.string().required("house number is required"),
  cityid: Yup.string().required("city is required"),
  stateid: Yup.string().required("state is required"),
  countryid: Yup.string().required("country is required"),
  pincode: Yup.string()
    .matches(/^\d{6}$/, "invalid pin code")
    .required("pin code is required"),
});
