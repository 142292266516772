import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import Button from "./Button";
import Input from "../components/Input";
import { format } from "date-fns";
import { useFormik } from "formik";
import { personaldetailsSchema } from "../schemas/personaldetailsSchema";
import axios from "axios";
import { toast } from "react-toastify";

import PhoneInput from "react-phone-number-input";

import { server } from "../config/config";
import { Method } from "../config/method";
import { setData } from "../store/SearchSlice";
import Select from "react-select";
import { SelectPicker } from "rsuite";

const PersonalDetails = ({ initialValues, updateUserData, handleNext, fetchUserData, loading }) => {

  const reduxStore = Method.GetReduxStore()
  const dispatch = Method.GetReduxDispatch()

  const {masterData, masterDataList} = reduxStore

  // const [initialValues, setInitialValues] = useState({
  //   firstname: "",
  //   lastname: "",
  //   dob: "",
  //   placeofbirth: "",
  //   email: "",
  //   contactno: "",
  //   address: {
  //     houseno: "",
  //     city: "",
  //     country: "",
  //     pincode: "",
  //   },
  // });

  const formik = useFormik({
    initialValues,
    validationSchema: personaldetailsSchema,
    onSubmit: async (values, action) => {
      try {
        // const cookies = new Cookies(null, { path: "/" });
        // const token = cookies.get("jwt");
        // // console.log("token", token);
        // const config = {
        //   headers: {
        //     "Content-type": "application/json",
        //     Authorization: token ? `Bearer ${token}` : null,
        //   },
        // };
        // const resp = await axios.patch(
        //   `${server}/api/user/updatepersonaldata`,
        //   values,
        //   config
        // );
        // const data = resp.data;
        // console.log(data);
        await updateUserData(values);
        handleNext();
      } catch (err) {
        console.log(err);
        toast(err.response.data);
        window.location.reload();
      }
      action.resetForm();
    },
  });
  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = formik;
  // console.log(values, errors);

  const getMasterData = async (field, payload = {}) => {
    const response = await Method.GetList(`${server}/api/${field.api}`, payload)
    if(response.status === 200){
      const resData = response.data;
      const dropdown_data = Method.GetDropDownData(resData, field.labelField);
      dispatch(setData({ masterData : {[field.masterData] : dropdown_data }, masterDataList : {[field.masterData] : resData}}))
    }else {
      dispatch(setData({ masterData : {[field.masterData] : [] }, masterDataList : {[field.masterData] : []}}))
    }
  };

  const fetchDropdownData = async (key, value) => {
    await Promise.all(
      formFields.map(async (f) => {
        if (f.fields) {
          await Promise.all(
            f.fields.map(async (field) => {

              if(field.dependentField == key && value){
                let payload = {
                  filter : {
                    [key] : [value]
                  }
                }
                await getMasterData(field, payload);
              }else if(field.dependentField && values[field.dependentField]){
                  let payload = {
                    filter : {
                      [field.dependentField] : [values[field.dependentField]]
                    }
                  }
                  await getMasterData(field, payload);

              } else if (field.type === 'dropdown' && !field.dependentField && !masterData[field.masterData]) {
                await getMasterData(field);
              } 
              

              if(field.name == key){
                setFieldValue(key.replace(/id$/, ''), Method.GetObjectFromArray(masterData[field.masterData],'value',value).label)
              }
            })
          );
        }
      })
    );
  };

  const stateManage = (field, value) => {
      if(field.name === "countryid"&& values[field.name]){
        if(values[field.name] && values[field.name] != value){
          setFieldValue('stateid','')
          setFieldValue('state','')
          setFieldValue('cityid','')
          setFieldValue('city','')
        }
      }else if(field.name === "stateid"){
        if(values[field.name] && values[field.name] != value){
          setFieldValue('cityid','')
          setFieldValue('city','')
        }
      }

  }
  
  useEffect(() => {
    async function first () {
      await fetchUserData()
      await fetchDropdownData();
    }
    first()
  }, []);
  

  const formFields = [
    {
      heading: "Personal Data",
      fields: [
        { name: "firstname", label: "First Name", type: "text" },
        { name: "lastname", label: "Last Name", type: "text" },
        { name: "dob", label: "Date of Birth", type: "date" },
        { name: "placeofbirth", label: "Place of Birth", type: "text" },
      ],
    },
    {
      heading: "Contact",
      fields: [
        { name: "email", label: "Email", type: "email", disabled : true },
        { name: "contactno", label: "Phone Number", type: "phonenumber" },
      ],
    },
    {
      heading: "Address",
      fields: [
        { name: "houseno", label: "House Address", type: "text" },
        { name: "countryid", label: "Country", type: "dropdown", masterData : 'countryData', api : 'country', labelField : 'country' },
        { name: "stateid", label: "State", type: "dropdown", masterData : 'stateData', api : 'state', labelField : 'state', dependentField : 'countryid' },
        { name: "cityid", label: "City", type: "dropdown", masterData : 'cityData', api : 'city', labelField : 'city', dependentField : 'stateid' },
        { name: "pincode", label: "Pincode", type: "number" },
      ],
    },
  ];
  return (
    <div className="tab-content" id="tab1">
      <form className="innertab" onSubmit={handleSubmit}>
        {formFields?.map((section) => {
          return (
            <div className="cols">
              <div className="heading">
                <h3>{section.heading}</h3>
              </div>
              <div className="filed pdrl">
                {section?.fields?.map((field) => {
                  return field.type == "date" ? (
                    <Input
                      type="date"
                      label={[field.label]}
                      name={[field.name]}
                      value={values[field.name]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={`Enter ${[field.label]}`}
                      className="fil form"
                      error={
                        errors[field.name] && touched[field.name]
                          ? errors[field.name]
                          : null
                      }
                    />
                  ) : field.type == "email" ? (
                    <Input
                      type="email"
                      disabled={field.disabled}
                      label={[field.label]}
                      name={[field.name]}
                      value={values[field.name]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={`Enter ${[field.label]}`}
                      className="fil form email"
                      error={
                        errors[field.name] && touched[field.name]
                          ? errors[field.name]
                          : null
                      }
                    />
                  ) : field.type == "number" ? (
                    <Input
                      type="number"
                      label={[field.label]}
                      name={[field.name]}
                      value={values[field.name]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={`Enter ${[field.label]}`}
                      className="fil form"
                      error={
                        errors[field.name] && touched[field.name]
                          ? errors[field.name]
                          : null
                      }
                    />
                  ) : field.type == "phonenumber" ? (
                    <div>
                      <PhoneInput
                        placeholder={`Enter ${[field.label]}`}
                        onChange={(val)=> setFieldValue(field.name, val)}
                        defaultCountry="IN"
                        value={values[field.name]}
                      />
                      {touched[field.name] && errors[field.name] ? (
                        <p style={{color : 'red', fontSize : '12px'}}>{errors[field.name]}</p>
                      ) : null}
                    </div>
                  ) : field.type == "dropdown" ? (
                    <div>
                    <SelectPicker
                      style={{ width: '100%' }}
                      data={masterData[field.masterData] || []}
                      name={field.name}
                      value={values[field.name]}
                      className="rsuit-dropdown-profile"
                      placeholder={`Enter ${[field.label]}`}
                      onChange={(val)=>{ setFieldValue(field.name, val); fetchDropdownData(field.name, val); stateManage(field, val)}}
                    />
                    {touched[field.name] && errors[field.name] ? (
                        <p style={{color : 'red', fontSize : '12px'}}>{errors[field.name]}</p>
                      ) : null}
                    </div>
                  ) : (
                    <Input
                      label={[field.label]}
                      name={[field.name]}
                      value={values[field.name]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={`Enter ${[field.label]}`}
                      className="fil form"
                      error={
                        errors[field.name] && touched[field.name]
                          ? errors[field.name]
                          : null
                      }
                    />
                  );
                })}
              </div>
            </div>
          );
        })}

        {/* <div className="cols">
          <div className="heading">
            <h3>Personal Data</h3>
          </div>
          <div className="filed pdrl">
            <Input
              label="First Name"
              name="firstname"
              value={values.firstname}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Enter first name"
              className="fil form"
              error={
                errors.firstname && touched.firstname ? errors.firstname : null
              }
            />
            <Input
              label="Last Name"
              name="lastname"
              value={values.lastname}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Enter Second name"
              className="fil form"
              error={
                errors.lastname && touched.lastname ? errors.lastname : null
              }
            />
            <Input
              label="Date of Birth"
              name="dob"
              type="date"
              value={values.dob}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Enter your Birth Date"
              className="fil form"
              error={errors.dob && touched.dob ? errors.dob : null}
            />
            <Input
              label="Place of Birth"
              name="placeofbirth"
              value={values.placeofbirth}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Enter your Birth Place"
              className="fil form"
              error={
                errors.placeofbirth && touched.placeofbirth
                  ? errors.placeofbirth
                  : null
              }
            />
          </div>
        </div> */}

        {/* <div className="cols second-col">
          <div className="heading">
            <h3>Contact</h3>
          </div>
          <div className="filed pdrl">
            <Input
              type="email"
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Enter email"
              className="fil form email"
              error={errors.email && touched.email ? errors.email : null}
            />
            <Input
              type="number"
              name="contactno"
              value={values.contactno}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Enter Your Phone Number"
              className="fil form phone"
              error={
                errors.contactno && touched.contactno ? errors.contactno : null
              }
            />
          </div>
        </div> */}

        {/* <div className="cols third-col">
          <div className="heading">
            <h3>Address</h3>
          </div>

          <div className="pdrl">
            <div className="fild">
              <Input
                label="House No."
                name="houseno"
                value={values.houseno}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder=""
                className="fil form"
                error={
                  errors.houseno && touched.houseno ? errors.houseno : null
                }
              />
            </div>
            <div className="filed">
              <Input
                label="City"
                name="city"
                value={values.city}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder=""
                className="fil form"
                error={errors.city && touched.city ? errors.city : null}
              />
              <Input
                label="State"
                name="state"
                value={values.state}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder=""
                className="fil form"
                error={errors.state && touched.state ? errors.state : null}
              />
              <Input
                label="Country"
                name="country"
                value={values.country}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder=""
                className="fil form"
                error={
                  errors.country && touched.country ? errors.country : null
                }
              />
              <Input
                label="Pincode"
                name="pincode"
                type="number"
                value={values.pincode}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder=""
                className="fil form"
                error={
                  errors.pincode && touched.pincode ? errors.pincode : null
                }
              />
            </div>
          </div>
        </div> */}

        <div className="btns">
          <Button label="Save" type="submit" className="save" disabled={loading}/>
        </div>
      </form>
    </div>
  );
};

export default PersonalDetails;
