import React from 'react'
import { Accessible, Collaborative, Innovative, aboutimage, aboutmisson, aboutvision, man, studentfocused } from '../images'

const About = () => {
  return (
    <section>
        <div class="herobanner about-hero">
            <div class="container">
                <div class="innerbanner">
                    <div class="left">
                        <h2>About <span class="courseBuddy">CourseBuddy</span> </h2>
                        <p>CourseBuddy was founded by Kamal Chibrani, a graduate student frustrated by the convoluted global university application process. After spending countless hours duplicating information across multiple applications and struggling to track scattered deadlines, Kamal knew there had to be a better way.</p>
                        <p>In 2023, he launched CourseBuddy to create a simpler, more transparent application experience for students and admissions teams worldwide.</p>
                    </div>
                    <div class="right">
                        <img src={aboutimage} alt=""/>
                    </div>
                </div>
                
            </div>
        </div>
       
        <div class="vision pd100 about-vision">
            <div class="container">
                <div class="inner-row">
                    <div class="list">
                        <div class="left">
                            <img src={aboutmisson} alt=""/>
                        </div>
                        <div class="left pdr">
                            <h2>Our <span class="mission-2">Mission</span></h2>
                            <p>CourseBuddy's mission is to transform university admissions through an integrated platform that unites applicants and institutions globally.</p>
                            <p>We simplify every step for students by providing tools to track applications, get reminders, verify documents, and connect directly with universities. Our AI algorithms also match students with best-fit schools tailored to their interests and academics.</p>
                            <p>For universities, we offer a centralized portal to easily manage and evaluate candidates from around the world on custom application forms. Useful features like plagiarism checks, analytics, and communication channels help admissions officers recruit and enroll better matched incoming classes.</p>
                            <p>By bridging students and universities, CourseBuddy brings order, efficiency, and transparency to the admissions process.</p>
                        </div>
                        
                    </div>
                    <div class="list">
                        <div class="left">
                            <h2>Our <span class="mission">Vision</span></h2>
                            <p>We envision a future where applying to universities worldwide is stress-free, straightforward, and transparent. Students should be able to submit applications in one click, while admissions teams can holistically evaluate candidates.</p>
                            <p>With CourseBuddy's global, integrated platform, this future is possible. We make admissions easier for all by utilizing technology to simplify, not complicate.</p>
                        </div>
                        <div class="left">
                            <img src={aboutvision} alt=""/>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div> 
       <div class="our-value pd100 " >
            <div class="container">
                <div class="innerrow">
                    <h2 class="ourheading">Our <span class="mission">Values</span> </h2>
                    <div class="innerlist">
                        <div class="left">
                            <img src={studentfocused} alt=""/>
                            <h3>Student-Focused</h3>
                            <p>We optimize for the applicant's experience at every step.</p>
                        </div>
                        <div class="left">
                            <img src={Collaborative} alt=""/>
                            <h3>Collaborative</h3>
                            <p>We work hand-in-hand with university partners to unite admissions.</p>
                        </div>
                        <div class="left">
                            <img src={Innovative} alt=""/>
                            <h3>Innovative</h3>
                            <p>We constantly improve our systems to push admissions tech forward.</p>
                        </div>
                        <div class="left">
                            <img src={Accessible} alt=""/>
                            <h3>Accessible</h3>
                            <p>We believe every motivated student deserves an affordable path to education.</p>
                        </div>
                    </div>
                </div>
            </div>
       </div>
        <div class="cta pd100">
            <div class="container">
                <div class="inner-cta">
                    <h2>
                        Connecting Every Student To A <span class="word">World Of University</span>
                    </h2>    
                    <p>While there are numerous diverse passages available for exploration, many have undergone alterations in their presentation. Some have been infused with humor, lending them a unique charm and wit.</p>
                    <a href="#" class="main-btn">Join Us Today</a>
                    <img src={man} alt="" class="ctaimage"/>
                </div>
                
            </div>
        </div>
    </section>
  )
}

export default About