import React, { useState } from "react";
import Button from "./Button";
import { logo, loginLogo } from "../images/index.js";
import { Link as ScrollLink } from "react-scroll";
import { NavLink, useLocation } from "react-router-dom";

const Header = ({ isLoggedIn, onLogout }) => {

  const [navOpen, setNavOpen] = useState(false);

  const handleToggle = (e) => {
    const navToggle = document.querySelector('.nav-toggle');
    const body = document.querySelector('body');
    if (navToggle && body) {
      navToggle.classList.toggle('active');
      body.classList.toggle('nav_open');
    }

    setNavOpen(!navOpen);
  }

  return (
    <header
      id="header"
      className="affix-top"
      data-spy="affix"
      data-offset-top="5"
    >
      <div className="header">
        <div className="header-wrap section">
          <div className="container">
            <div className="header-main section">
              <div className="logo">
                <NavLink to="/">
                  <img src={logo} className="img-fluid" alt="Logo" />
                </NavLink>
              </div>

              <div className="header-right">
                <div className="nav-main nav section">
                  <ul id="menu-header-menu" className="menu">
                    {/* <li className="menu-item">
                      <NavLink to="/">Home</NavLink>
                    </li> */}
                    <li className="menu-item for-phone" onClick={handleToggle}>
                      <NavLink to="/dashboard">Dashboard</NavLink>
                    </li>
                    <li className="menu-item" onClick={handleToggle}>
                      <NavLink to="/university">University</NavLink>
                    </li>
                    <li className="menu-item" onClick={handleToggle}>
                      <NavLink to="/about">About</NavLink>
                    </li>
                    <li className="menu-item">
                      <ScrollLink to="footer" smooth={true} duration={500} onClick={handleToggle}>
                        Contact
                      </ScrollLink>
                    </li>
                  </ul>
                </div>
                 <div className="res_navigation">
                 <a
                    onClick={handleToggle}
                    className="nav-toggle"
                    id="trigger-overlay"
                  >
                    <span className="top"></span>
                    <span className="middle"></span>
                    <span className="bottom"></span>
                  </a>
                </div>
              </div>
              {/* <div className="header-right">
                <div className="nav-main nav section">
                  <ul id="menu-header-menu" className="menu">
                    <li className="menu-item menu-item-has-children">
                      <a href="#">Courses</a>
                      <ul className="sub-menu">
                        <li className="menu-item">
                          <a href="#">menu</a>
                        </li>
                        <li className="menu-item">
                          <a href="#">menu two</a>
                        </li>
                        <li className="menu-item">
                          <a href="#">menu</a>
                        </li>
                        <li className="menu-item">
                          <a href="#">menu two</a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-item">
                      <a href="#">About Us</a>
                    </li>
                    <li className="menu-item">
                      <a href="#">Contact</a>
                    </li>
                    <li className="menu-item" style={{ display: "none" }}>
                      <a href="#">My Dashboard</a>
                    </li>
                  </ul>
                </div>
               <div className="res_navigation">
                  <a
                    href="javascript:void(0)"
                    className="nav-toggle"
                    id="trigger-overlay"
                  >
                    <span className="top"></span>
                    <span className="middle"></span>
                    <span className="bottom"></span>
                  </a>
                </div>
              </div> */}

              <div className="rightsidebar">
                {isLoggedIn ? (
                  <div className="afterlogin">
                    <NavLink to="/dashboard" className="my">
                      Dashboard
                    </NavLink>
                    <NavLink to="/" className="login" onClick={onLogout}>
                      Logout
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          d="M2.07031 5C3.45351 2.6088 6.03893 1 9.00004 1C13.4183 1 17 4.5816 17 9C17 13.4184 13.4183 17 9.00004 17C6.03893 17 3.45351 15.3912 2.07031 13M8.99996 12.2L12.1999 9M12.1999 9L8.99996 5.8M12.1999 9H1"
                          stroke="#001919"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </NavLink>
                  </div>
                ) : (
                  <div className="right-login">
                    <NavLink to="/login" className="link-menu">
                      <img src={loginLogo} alt="loginlogo" />
                      Login
                    </NavLink>
                    {/* <Link to="/signup">Sign Up</Link> */}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
