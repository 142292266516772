import React, { useEffect, useState } from "react";
import { server } from "../config/config";
import { Method } from "../config/method";
import { setData } from "../store/SearchSlice";

const Degree = ({ handleNext, handlePrevious, handleChange }) => {

  const reduxStore = Method.GetReduxStore()
  const dispatch = Method.GetReduxDispatch()

  const fetchData = async () => {
    const response = await Method.GetList(`${server}/api/degree`)
    if(response.status === 200){
      const resData = response.data;
      const dropdown_data = Method.GetDropDownData(resData, 'degree');
      dispatch(setData({ masterData : {degreeList : dropdown_data }, masterDataList : {degreeList : resData}}))
    }else {
      dispatch(setData({ masterData : {degreeList : [] }, masterDataList : {degreeList : []}}))
    }
  };

  // const cycleData = Method.ConvertJsonParse(Method.GetLocalStorage('cycle'))
  const cycleData = Method.GetSessionStorage('cycle')
  const selectedDegree = cycleData.degree

  useEffect(() => {
    fetchData();
  }, []);

  
  return (
    // <div
    //   style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    // >
    //   <label
    //     style={{ marginBottom: "20px", fontSize: "18px", fontWeight: "bold" }}
    //   >
    //     Select Degree:
    //   </label>

    //   <div
    //     style={{
    //       display: "flex",
    //       flexDirection: "column",
    //       alignItems: "flex-start",
    //       marginBottom: "20px",
    //     }}
    //   >
    //     {
    //       reduxStore.masterData?.degreeList?.map((o) => (
    //         <div key={o.value} style={{ marginBottom: "10px" }}>
    //           <input
    //             type="radio"
    //             id={o.value}
    //             name="degree"
    //             value={o.value}
    //             style={{ marginRight: "10px" }}
    //             checked={selectedDegree == o.value}
    //             onChange={() =>{ handleChange('degree', o.value, 'radio') }}
    //           />
    //           <label htmlFor={o.value}>{o.label}</label>
    //         </div>
    //       ))
    //     }

    //   </div>

    //   <div
    //     style={{
    //       display: "flex",
    //       justifyContent: "space-between",
    //       width: "200px",
    //     }}
    //   >
    //     <button
    //       onClick={handlePrevious}
    //       style={{
    //         padding: "10px",
    //         backgroundColor: "#4CAF50",
    //         color: "white",
    //         border: "none",
    //         borderRadius: "5px",
    //         cursor: "pointer",
    //       }}
    //     >
    //       Previous
    //     </button>
    //     <button
    //       onClick={handleNext}
    //       style={{
    //         padding: "10px",
    //         backgroundColor: "#008CBA",
    //         color: "white",
    //         border: "none",
    //         borderRadius: "5px",
    //         cursor: "pointer",
    //       }}
    //     >
    //       Next
    //     </button>
    //   </div>
    // </div>

    <div class="education pd100 country">
            <div class="container">
                <div class="innerrow">
                    <div class="heading">
                        <h2>Which degree do you wish to pursue?</h2>
                    </div>
                    <div class="list-country">
                      {
                        reduxStore.masterData?.degreeList?.map((o) => (
                          <div class="left">
                              <input 
                                type="radio" 
                                name="degree"
                                value={o.value}
                                checked={selectedDegree == o.value}
                                onChange={() =>{ handleChange('degree', o.value, 'radio') }}
                              /> 
                              <img src="images/degree.svg" alt=""/>
                              <h3>{o.label}</h3>
                              <span></span>
                          </div>
                        ))
                      }
                    </div>
                    <div class="btn">
                        <button class="main-btn" onClick={handlePrevious}>Previous</button>
                        <button class="main-btn" onClick={handleNext}>Next</button>
                    </div>
                </div>
            </div>
        </div>
  );
};

export default Degree;
