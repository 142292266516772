import React, { useEffect, useState } from "react";
import { Method } from "../config/method";
import Input from "./Input";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "./Button";
import { Modal } from "rsuite";
import { server } from "../config/config";
import { setData } from "../store/SearchSlice";

const HighestEducation = ({ handleNext, handlePrevious, handleChange }) => {

  const [open, setOpen] = useState(false);

  const reduxStore = Method.GetReduxStore()
  const dispatch = Method.GetReduxDispatch()

  const fetchData = async () => {
    const response = await Method.GetList(`${server}/api/highesteducation`)
    if(response.status === 200){
      const resData = response.data;
      const dropdown_data = Method.GetDropDownData(resData, 'highesteducation');
      dispatch(setData({ masterData : {highesteducationList : dropdown_data }, masterDataList : {highesteducationList : resData}}))
    }else {
      dispatch(setData({ masterData : {highesteducationList : [] }, masterDataList : {highesteducationList : []}}))
    }
  };

  // const cycleData = Method.ConvertJsonParse(Method.GetLocalStorage('cycle'))
  const cycleData = Method.GetSessionStorage('cycle')
  const selectedHighEdu = cycleData.highesteducation

  useEffect(() => {
    fetchData();
  }, []);

  const data = [
    { value : 1, label : 'Undergraduate Degree'},
    { value : 2, label : 'Postgraduate Degree'},
    { value : 3, label : 'Undergraduate Diploma'},
    { value : 4, label : 'Postgraduate Diploma'},
  ]

  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues : {
      highesteducationpr : ''
    },
    validationSchema: Yup.object({
      highesteducationpr: Yup.number()
        .required("Score is required")
        .min(0, "Score must be between 0 and 100")
        .max(100, "Score must be between 0 and 100"),
    }),
    onSubmit: async (values, action) => {
      handleChange('highesteducationpr', values.highesteducationpr)
      setOpen(false)
    },
  });
  
  const handleNextStage = () => {
    if(cycleData.highesteducationpr){
      handleNext()
    }else{
      setOpen(true)
    }
  }
  return (
    <div class="education pd100 country education-level">

            <Modal size='xs' open={open} onClose={()=>{setFieldValue('highesteducationpr',''); setOpen(false)}}>
              <Modal.Header>
                <Modal.Title style={{textAlign: 'center'}}>Enter your Score</Modal.Title>
              </Modal.Header>
                <form className="innertab" onSubmit={handleSubmit}>
              <Modal.Body>
                  <Input
                    style={{width : '100%'}}
                    type="number"
                    name={'highesteducationpr'}
                    value={values.highesteducationpr}
                    onChange={({target}) => setFieldValue('highesteducationpr', target.value)}
                    placeholder={`Enter Your Score`}
                    className="fil form"
                    error={
                      errors.highesteducationpr && touched.highesteducationpr
                        ? errors.highesteducationpr
                        : null
                    }
                  />
              </Modal.Body>
              <Modal.Footer>
                <div className="btns">
                  <Button label="Continue" type="submit" className="save" />
                </div>
              </Modal.Footer>
                </form>
            </Modal>

            <div class="container">
                <div class="innerrow">
                    <div class="heading">
                        <h2>What is your highest education level?</h2>
                    </div>
                    <div class="list-country">
                    {
                      reduxStore.masterData?.highesteducationList?.map((o) => (
                        <div class="left">
                            <input 
                              type="radio" 
                              name="degree"
                              value={o.value}
                              checked={selectedHighEdu == o.value}
                              onChange={() =>{ handleChange('highesteducation', o.value, 'radio'); setOpen(true) }}
                            />
                            <h3>{o.label}</h3>
                            <span></span>
                        </div>
                      ))
                    }
                    </div>
                    <div class="btn">
                        <button onClick={handlePrevious} class="main-btn">Previous</button>
                        <button onClick={handleNextStage} class="main-btn">Next</button>
                    </div>
                </div>
            </div>
        </div>
  );
};

export default HighestEducation;
