import axios from "axios"
import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";
import AWS from 'aws-sdk'

// Configure AWS SDK
AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_Access_Key_Id || 'AKIA4TDY3JHOZCRFDU5X',
    secretAccessKey: process.env.REACT_APP_AWS_Secret_Access_Key || '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',
    region: process.env.REACT_APP_AWS_Region || 'us-east-1'
});

const s3 = new AWS.S3();

export const Method = {
    GetReduxStore (){
        const reduxStore = useSelector(store => store.store)
        return reduxStore
    },
    GetReduxDispatch (){
        return useDispatch()
    },
    SetLocalStorage (key, data) {
        return localStorage.setItem(key, data)
    },
    GetLocalStorage (key) {
        return localStorage.getItem(key)
    },
    RemoveLocalStorage (key) {
        return localStorage.removeItem(key)
    },
    SetSessionStorage(key, value, expirationMinutes = 120) {
        const now = new Date();
        const expirationTime = now.getTime() + expirationMinutes * 60 * 1000;
        const dataWithExpiration = { value, expirationTime };
        sessionStorage.setItem(key, JSON.stringify(dataWithExpiration));
    },
    GetSessionStorage(key){
        const dataString = sessionStorage.getItem(key);
        if (dataString) {
          const { value, expirationTime } = JSON.parse(dataString);
          const now = new Date();
      
          if (now.getTime() < expirationTime) {
            return value;
          }
          // Data has expired, clear it from sessionStorage
          sessionStorage.removeItem(key);
        }
        return null;
    },
    RemoveSessionStorage(key) {
        sessionStorage.removeItem(key);
    },
    ConvertJsonString(data) {
        return JSON.stringify(data)
    },
    ConvertJsonParse(data) {
        return JSON.parse(data)
    },
    async GetList (api, payload = {}) {
        const cookies = new Cookies(null, { path: "/" });
        const token = cookies.get("jwt");
        const headers = {
            Authorization: token ? `Bearer ${token}` : null,
        }
        const response = await axios.post(api, payload,{ headers })
        if(response.status === 200){
            return response.data
        }else {
            return response
        }
    },
    GetDropDownData (data, field) {
        let dropdown_data = [];
        if (data.length) {
          data.forEach((obj) => {
            // console.log(obj, field);
            dropdown_data.push({ value: obj._id, label: obj[field] });
          });
        }
        return dropdown_data;
    },
    GetObjectFromArray(arr = [], key, value) {
        return arr.find( o => o[key] === value)
    },
    GetIndexFromArray(arr = [], key, value) {
        return arr.findIndex( o => o[key] === value)
    },
    UploadFileToS3 (file) {
        return new Promise((resolve, reject) => {
            if (!file) {
                reject(new Error('No file provided'));
                return;
            }
    
            const params = {
                Bucket: process.env.REACT_APP_Bucket_Name || 'coursebuddydev1',
                Key: `tempfiles/${Date.now()}-${file.name}`,
                Body: file,
                ContentType : file.type
            };
            
            s3.upload(params, (err, data) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(data.Location);
                }
            });
        });
    }
}