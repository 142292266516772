import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import Button from "./Button";
import Input from "../components/Input";
import { useFormik } from "formik";
import { educationaldetailsSchema } from "../schemas/educationaldetailsSchema";
import axios from "axios";
import { toast } from "react-toastify";

import { server } from "../config/config";

const EducationalDetails = ({ initialValues, updateUserData, handleNext, loading }) => {
  // const [initialValues, setInitialValues] = useState({
  //   tenth: {
  //     passingyear: "",
  //     percentage: "",
  //   },
  //   twelfth: {
  //     passingyear: "",
  //     percentage: "",
  //   },
  //   graduate: {
  //     passingyear: "",
  //     percentage: "",
  //   },
  // });
  
  let formik = useFormik({
    initialValues,
    validationSchema: educationaldetailsSchema,
    onSubmit: async (values, action) => {
      try {
        // const cookies = new Cookies(null, { path: "/" });
        // const token = cookies.get("jwt");
        // // console.log("token", token);
        // const config = {
        //   headers: {
        //     "Content-type": "application/json",
        //     Authorization: token ? `Bearer ${token}` : null,
        //   },
        // };
        // const resp = await axios.patch(
        //   `${server}/api/user/updateedudata`,
        //   values,
        //   config
        // );
        // const data = resp.data;
        // console.log(data);
        // console.log(values);
        await updateUserData(values);
        handleNext();
      } catch (err) {
        console.log(err);
        toast(err.response.data);
      }
      action.resetForm();
    },
  });
  let { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    formik;

  const formFields = [
    {
      heading : '10th Education',
      fields : [
        { name: 'tenthboard', label: '10th Boards', type: 'text'},
        { name : 'tenthyear', label : '10th Passing Year', type : 'number'},
        { name : 'tenthpr', label : '10th Percentage/CGPA', type : 'number'},
      ]
    },
    {
      heading : '12th Education',
      fields : [
        { name: 'twelveboard', label: '12th Boards', type: 'text'},
        { name : 'twelveyear', label : '12th Passing Year', type : 'number'},
        { name : 'twelvepr', label : '12th Percentage/CGPA', type : 'number'},
      ]
    },
    {
      heading : 'Graduation',
      fields : [
        { name: 'graduateuniversity', label: 'University Name', type: 'text'},
        { name : 'graduateyear', label : 'Graduation Passing Year', type : 'number'},
        { name : 'graduatepr', label : 'Graduation Percentage/CGPA', type : 'number'},
      ]
    }
  ]

  return (
    <div className="tab-content" id="tab2">
      <form className="innertab" onSubmit={handleSubmit}>

      {
        formFields?.map( section => {
          return (
            <div className="cols">
              <div className="heading">
                <h3>{section.heading}</h3>
              </div>
              <div className="filed pdrl">
                {
                  section?.fields?.map( field => {
                    return (
                        field.type == 'date'?
                          <Input
                            type="date"
                            label={[field.label]}
                            name={[field.name]}
                            value={values[field.name]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder={`Enter ${[field.label]}`}
                            className="fil form"
                            error={ errors[field.name] && touched[field.name] ? errors[field.name] : null }
                          />
                        :
                        field.type == 'email'?
                          <Input
                            type="email"
                            label={[field.label]}
                            name={[field.name]}
                            value={values[field.name]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder={`Enter ${[field.label]}`}
                            className="fil form email"
                            error={ errors[field.name] && touched[field.name] ? errors[field.name] : null }
                          />
                        :
                        field.type == 'number'?
                          <Input
                            type="number"
                            label={[field.label]}
                            name={[field.name]}
                            value={values[field.name]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder={`Enter ${[field.label]}`}
                            className="fil form"
                            error={ errors[field.name] && touched[field.name] ? errors[field.name] : null }
                          />
                        : <Input
                          label={[field.label]}
                          name={[field.name]}
                          value={values[field.name]}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={`Enter ${[field.label]}`}
                          className="fil form"
                          error={ errors[field.name] && touched[field.name] ? errors[field.name] : null }
                        />
                    )
                  })
                }
              </div>
            </div>
          )
        })
      }

        {/* <div className="cols">
          <div className="heading">
            <h3>10th Education</h3>
          </div>
          <div className="filed pdrl">
            <Input
              label="10th Passing Year"
              name="tenthyear"
              type="number"
              value={values.tenthyear}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder=""
              className="fil form"
              error = {errors.tenthyear && touched.tenthyear ? errors.tenthyear : null}
            />
            <Input
              label="10th Percentage"
              name="tenthpr"
              type="number"
              value={values.tenthpr}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder=""
              className="fil form"
              error = {errors.tenthpr && touched.tenthpr ? errors.tenthpr : null}
            />
          </div>
        </div>
        <div className="cols second-col">
          <div className="heading">
            <h3>12th Education</h3>
          </div>
          <div className="filed pdrl">
            <Input
              label="12th Passing Year"
              name="twelveyear"
              type="number"
              value={values.twelveyear}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder=""
              className="fil form"
              error = {errors.twelveyear && touched.twelveyear ? errors.twelveyear : null}
            />
            <Input
              label="12th Percentage"
              name="twelvepr"
              type="number"
              value={values.twelvepr}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder=""
              className="fil form"
              error = {errors.twelvepr && touched.twelvepr ? errors.twelvepr : null}
            />
          </div>
        </div>
        <div className="cols third-col">
          <div className="heading">
            <h3>Graduation</h3>
          </div>
          <div className="filed pdrl">
            <Input
              label="Graduation Passing Year"
              name="graduateyear"
              type="number"
              value={values.graduateyear}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder=""
              className="fil form"
              error = {errors.graduateyear && touched.graduateyear ? errors.graduateyear: null}
            />
            <Input
              label="Graduation Percentage"
              name="graduatepr"
              type="number"
              value={values.graduatepr}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder=""
              className="fil form"
              error = {errors.graduatepr && touched.graduatepr ? errors.graduatepr : null}
            />
          </div>
        </div> */}
        <div className="btns">
          <Button label="Save" type="submit" className="save" disabled={loading} />
        </div>
      </form>
    </div>
  );
};

export default EducationalDetails;
