import React from 'react'
import { Navigate, Outlet } from 'react-router-dom';
import Cookies from 'universal-cookie';

const cookies = new Cookies(null, {path: '/'});

const ProtectedRoute = ({redirectPath = '/signup' }) => {
    if (cookies.get("jwt") === undefined) {

        return <Navigate to={redirectPath} replace />
    }
    return <Outlet/>;
}
export default ProtectedRoute