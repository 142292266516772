import React from "react";

const Input = ({
  label = "",
  name = "",
  type = "text",
  accept = "",
  className = "",
  inputclassName = "",
  isRequired = false,
  disabled = "",
  placeholder = "",
  value = "",
  onKeyDown = () => {},
  onChange = () => {},
  onBlur = () => {},
  error = null,
  style = {}
}) => {
  return (
    <div className={`${className}`}>
      <label for="">{label}</label>
      <input
        type={type}
        id={name}
        accept={accept}
        className={` ${inputclassName}`}
        placeholder={placeholder}
        required={isRequired}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        disabled={disabled}
        style={style}
      />
      <p className="" style={{ color: "red", fontSize: '12px'  }} >
        {error}
      </p>
    </div>
  );
};

export default Input;
