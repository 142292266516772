import React, { useEffect, useState } from "react";
import { man } from "../images/index.js";
import SearchBar from "../components/SearchBar";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { updateSearchData } from "../store/SearchSlice.js";
import axios from "axios";
import { Method } from "../config/method.js";

import { server } from "../config/config";

const SearchDetails = ({ isLoggedIn }) => {
  const [univList, setUnivList] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const reduxD = useSelector((state) => state.store);
  const { searchData } = reduxD;

  const setFilters = (filterData = {}) => {
    let filters = {}
    Object.keys(searchData).forEach( key => {
      if(filterData[key]){
        filters[`${key}id`] = filterData[key] 
      }
    })
    const userData = Method.GetSessionStorage('userdata')
    if(userData){
      filters['userid'] = userData._id
    }
    return filters
  };

  const fetchCourseData = async (filters = searchData) => {
    setLoading(true)
    try {
      const res = await axios.post(`${server}/api/course`, {
        filter : setFilters(filters),
      });
      if(res.data.status == 200){
        setUnivList(res.data.data);
      }
      setLoading(false);
      
    } catch (err) {
      console.error("Error getting task:", err);
      setLoading(false);
    }
  };

  const handleUniversitydata = (data) => {
    Method.SetSessionStorage('course', data);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    // const cycle = Method.ConvertJsonParse(Method.GetLocalStorage('cycle'))
    const cycle = Method.GetSessionStorage('cycle')
    const updatedSearchData = { ...searchData };
      Object.keys(searchData).forEach( key => {
        if(cycle[key]){
          updatedSearchData[key] = cycle[key]
        }
      })
    dispatch(updateSearchData(updatedSearchData))
    fetchCourseData(updatedSearchData);
  }, []);

  return (
    <section>
      <div className="herobanner university-search">
        <div className="container">
          <div class="innerbanner">
            <h1>Find Your Dream University</h1>
          </div>
          <SearchBar fetchCourseData={fetchCourseData} />
        </div>
      </div>

      <div className="university-search-section chance">
        <div className="container">
        {
          univList?.length ?
          <ul>
            {univList?.length &&
              (univList.map((item, i) => (
                <li>
                  <div className="box-wrap">
                    <div className="title">
                      <h2 className="course">{item.course}</h2>
                      <h3 className="university">{item.university}</h3>
                    </div>
                    <div className="content-wrap">
                      <div className="fees-chance-wrap">
                        <h3 style={{marginLeft: 0}} >
                          <span>Tuition Fees</span>
                          {item.fees}
                        </h3>
                        {isLoggedIn && (
                          <h3>
                            <span>Chances</span>{item.universitychances}%
                          </h3>
                        )}
                      </div>
                      <div className="btn-wrap">
                        {
                          isLoggedIn ? (
                            item.isCourseApplied ? 
                              <NavLink to="../application" onClick={() => handleUniversitydata(item) }>{item.universitychances == 100 ? 'View Application' : 'Complete Application'}</NavLink>
                            : <NavLink to="../application" onClick={() => handleUniversitydata(item) }>Apply</NavLink>
                          ) :
                            <NavLink to="../signup">Check your Chances</NavLink>
                        }
                      </div>
                    </div>
                  </div>
                </li>
              )))}
          </ul>
          :  loading ? <h3 style={{textAlign : 'center'}}>Loading.....</h3>
          : <h3 style={{textAlign : 'center'}}>Data Not Found</h3>
          }
        </div>
      </div>

      <div className="cta pd100">
        <div className="container">
          <div className="inner-cta">
            <h2>
              Connecting Every Student To A{" "}
              <span className="word">World Of University</span>
            </h2>
            <p>
              While there are numerous diverse passages available for exploration, many have undergone alterations in their presentation. Some have been infused with humor, lending them a unique charm and wit.
            </p>
            <NavLink className="main-btn">
              Join Us Today
            </NavLink>
            <img src={man} alt="" className="ctaimage" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SearchDetails;
