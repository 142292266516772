import { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import DefaultRoutes from "./components/DefaultRoutes";
import { BrowserRouter, Route, Routes } from "react-router-dom";

function App() {
  const cookies = new Cookies(null, { path: "/" });
  const [isLoggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    if (cookies.get("jwt") !== undefined) {
      setLoggedIn(true);
    }
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="login" element={<Login setLoggedIn={setLoggedIn} />} />
        <Route path="signup" element={<Signup setLoggedIn={setLoggedIn}/>} />
        <Route path="*" element={<DefaultRoutes/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
