import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import Button from "./Button";
import Input from "../components/Input";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { Method } from "../config/method";

import { server } from "../config/config";

const UniversitySpecific = ({ handleUpdateApply }) => {
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitChance, setSubmitChance] = useState(0)

  const fetchQuestion = async () => {
    setLoading(true)
    const cookies = new Cookies(null, { path: "/" });
    const token = cookies.get("jwt");
    const config = {
      headers: {
        Authorization: token ? `Bearer ${token}` : null,
      },
    };
    const resp = await axios.post(
      `${server}/api/question`,
      { filter: { universityid : Method.GetSessionStorage('course')?.universityid } },
      config
    );
    const questionData = resp.data.data;
    setQuestions(Object.assign(questions, questionData));

    const tempInitialValues = {};
    for (let i = 0; i < questionData.length; i++) {
      tempInitialValues[questionData[i]._id] = "";
    }

    // Set Already Answered data
    if(questionData.length){
      const payload = {
        filter : {
          universityid : Method.GetSessionStorage('course')?.universityid,
          userid : Method.GetSessionStorage('userdata')._id
        }
      }
      const response = await Method.GetList(`${server}/api/answer`, payload)
      setSubmitChance(response.universitychances)

      if(response.status == 200 && response.data.length){
        let answerArr = response.data[0].answerdata || []
        for(let j=0 ; j<answerArr.length ; j++ ){
          let ques = Method.GetObjectFromArray(questionData, '_id', answerArr[j].questionid)
          if(Object.keys(ques)){
            tempInitialValues[ques._id] = answerArr[j].answer
          }
        }
      }
    }

    formik.setValues(tempInitialValues);
    setLoading(false)
  };

  useEffect(() => {
    fetchQuestion();
  }, []);

  const insertAnswerData = async (payload) => {
    try {
      const cookies = new Cookies(null, { path: "/" });
      const token = cookies.get("jwt");
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: token ? `Bearer ${token}` : null,
        },
      };
      const resp = await axios.post(
        `${server}/api/answer/add`,
        payload,
        config
      );
      const data = resp.data;
      if(data.status === 200){
        toast.success(data.message);
      }
  } catch (e) {
    console.log(e);
  }
  };

  let formik = useFormik({
    initialValues : {},
    onSubmit: async (values, action) => {
      let ansFilter = {}
      for (const [key, value] of Object.entries(values)) {
        if(value){
          ansFilter[key] = value
        }
      }

      let answerdata = []
      Object.keys(ansFilter)?.map( val => {
        // console.log(f,questions);
      })
      for (const [key, value] of Object.entries(ansFilter)) {
        let question = Method.GetObjectFromArray(questions,'_id',key)
        answerdata.push({questionid : question._id, question : question.question , answer :value})
      }

      let payload = {
        answerdata,
        universityid : questions[0].universityid,
        university : questions[0].university,
        userid : Method.GetSessionStorage('userdata')._id
      }

      if(answerdata.length){
        await insertAnswerData(payload)
        await handleUpdateApply()
        await fetchQuestion()
      }

      // try {
      //   // Api post call
      // } catch (err) {
      //   console.log(err);
      //   toast(err.response.data);
      // }
      // action.resetForm();
    },
  });
  let { values, errors, touched, handleBlur, handleChange, handleSubmit } = formik;

  return (
    <div className="tab-content" id="tab4">
      {questions?.length ? (
        <form className="innertab" onSubmit={handleSubmit}>
          <div className="cols">
            <div className="heading">
              <h3>Question</h3>
            </div>
            <div className="filed pdrl">
            {questions?.length &&
              questions.map((item, i) => (
                <>
                {/* {console.log(item)} */}
                    <Input
                      label={`${item.question}`}
                      name={item._id}
                      isRequired={false}
                      value={values[item._id]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder=""
                      className="fil form"
                    />
                </>
              ))}
              </div>
          </div>

      
          <div className="btns">
            <Button label="Save" type="submit" className="save" />
            <Button onClick={()=>handleUpdateApply(true)} label="Submit Application" className="save" disabled={submitChance !== 100}/>
          </div>
        </form>
      ) :  
      loading ? <h3 style={{textAlign : 'center'}}>Loading.....</h3>
      : <h3 style={{textAlign : 'center'}}>Data Not Found</h3>}
    </div>
  );
};

export default UniversitySpecific;
