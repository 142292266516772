import React, { useState, useEffect } from "react";
import Country from "../components/Country.js";
import Degree from "../components/Degree.js";
import HighestEducation from "../components/HighestEducation.js";
import Subject from "../components/Subject.js";
import AcademicTest from "../components/AcademicTest.js";
import EnglishTest from "../components/EnglishTest.js";
import { useSelector, useDispatch } from "react-redux";
import { updateCurrentStep, updateSearchData } from "../store/SearchSlice.js";
import { Method } from "../config/method.js";
import { useNavigate } from "react-router-dom";

const Info = () => {

  const reduxStore = useSelector((state) => state.store);
  const dispatch = useDispatch()
  const navigate = useNavigate();

  // const cycleData = Method.ConvertJsonParse(Method.GetLocalStorage('cycle'))
  const cycleData = Method.GetSessionStorage('cycle')

  const [temp, setTemp] = useState(0)

  const handleNext = () => {
    dispatch(updateCurrentStep(reduxStore.currentstep + 1))
  };

  const handlePrevious = () => {
    dispatch(updateCurrentStep(reduxStore.currentstep - 1));
  }

const handleChange = async (key, value, type) => {
  let newData = cycleData

  // session data manage for cycle
  if(type == 'checkbox'){
    const isSelected = cycleData[key].includes(value);
    if (isSelected) {
      newData[key] = cycleData[key].filter((val) => val !== value)
    } else {
      newData[key] = [...cycleData[key], value]
    }
  }else if(type == 'radio'){
    newData[key] = value
  }else {
    newData[key] = value
  }

  // redux data manage for search
  if(key == 'country' || key == 'subject' || key == 'degree'){
    let temp = { ...reduxStore.searchData }
    if(type == 'checkbox'){
      const isSelected = temp[key].includes(value);
      if (isSelected) {
        temp[key] = temp[key].filter((val) => val !== value)
      } else {
        temp[key] = [...temp[key], value]
      }
    }else{
      temp[key] = value
    }
    dispatch(updateSearchData(temp))
  }

  // await Method.SetLocalStorage('cycle', Method.ConvertJsonString(newData))
  await Method.SetSessionStorage('cycle', newData)
  setTemp(temp+1)
}

useEffect(() => {
  window.scrollTo(0, 0);
  // const cycle = Method.ConvertJsonParse(Method.GetLocalStorage('cycle'))
  const cycle = Method.GetSessionStorage('cycle')
  if(cycle){
    const keys = ['country', 'degree', 'highesteducation', 'subject', 'englishtest', 'academictest']
    const index = keys.findIndex( key => {
      const value = cycle[key];
      return value === '' || value === undefined || (Array.isArray(value) && value.length === 0);
    })
    if(index >= 0){
      dispatch(updateCurrentStep(index))
    }else{
      navigate('/')
    }
  }else{
    // Method.SetLocalStorage('cycle',Method.ConvertJsonString({
    Method.SetSessionStorage('cycle',{
      country : [],
      degree : '',
      highesteducation : '',
      highesteducationprtype : 0,
      highesteducationpr : '',
      subject : [],
      englishtest : '',
      englishtestpr : '',
      academictest : '',
      academictestpr : '',
    })
  }
}, []);

useEffect( () => {}, [temp])

return (
  <section>
    {
      reduxStore.currentstep === 0 ? 
        <Country handleNext={handleNext} handlePrevious={handlePrevious} handleChange={handleChange}/>
      : reduxStore.currentstep === 1 ?
        <Degree handleNext={handleNext} handlePrevious={handlePrevious} handleChange={handleChange}/>
      : reduxStore.currentstep === 2 ?
        <HighestEducation handleNext={handleNext} handlePrevious={handlePrevious} handleChange={handleChange}/>
      : reduxStore.currentstep === 3 ?
        <Subject handleNext={handleNext} handlePrevious={handlePrevious} handleChange={handleChange}/>
      : reduxStore.currentstep === 4  ?
        <EnglishTest handleNext={handleNext} handlePrevious={handlePrevious} handleChange={handleChange}/>
      : reduxStore.currentstep === 5  ? 
        <AcademicTest handleNext={handleNext} handlePrevious={handlePrevious} handleChange={handleChange}/>
      : null
    }
  </section>
);
};

export default Info;
