import React from "react";
import Slider from "react-slick";
import {
  saveTime,
  reduceStress,
  increaseAccess,
  statusUpdates,
  chatWithUniversities,
} from "../images/index.js";

const Benefits = () => {
  const benefitsSettings = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="benefits pd100">
      <div className="container">
        <div className="inner-row">
          <div className="top">
            <h2>
              Benefits of <span className="courseBuddy">CourseBuddy</span>{" "}
            </h2>
            <p>
              Our platform provides major advantages for students applying to
              universities.
            </p>
          </div>
          <div className="benefits-list">
            <Slider {...benefitsSettings}>
              <div className="left">
                <img src={saveTime} alt="" />
                <h3>Increase Access</h3>
                <p>Apply to more universities in less time.</p>
              </div>
              <div className="left">
                <img src={reduceStress} alt="" />
                <h3>Reduce Stress</h3>
                <p>Easy tracking and organization reduces anxiety.</p>
              </div>
              <div className="left">
                <img src={increaseAccess} alt="" />
                <h3>Increase Access</h3>
                <p>Apply to more universities in less time.</p>
              </div>
              <div className="left">
                <img src={statusUpdates} alt="" />
                <h3>Status Updates</h3>
                <p>
                  Real-time tracking gives peace of mind through all application
                  and decision phases.
                </p>
              </div>
              <div className="left">
                <img src={chatWithUniversities} alt="" />
                <h3>Chat with universities</h3>
                <p>
                  Direct communication with admissions officers to learn more
                  about fit.
                </p>
              </div>
              <div className="left">
                <img src={increaseAccess} alt="" />
                <h3>Increase Access</h3>
                <p>Apply to more universities in less time.</p>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Benefits;
