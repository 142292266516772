import React, { useEffect } from "react";
import { Method } from "../config/method";
import { server } from "../config/config";
import { setData } from "../store/SearchSlice";

const Subject = ({ handleNext, handlePrevious, handleChange }) => {

  const data = ['Management','Engineering','Computers And Data Science','Design','Finance And Banking','Law','Humanities And Social Sciences','Sciences','Medicine And Pharma','Performing And Creative Arts','Media And Journalism','Hospitality And Tourism','Marketing And Advertising','Sports And Nutrition','Architecture']

  const reduxStore = Method.GetReduxStore()
  const dispatch = Method.GetReduxDispatch()

  const fetchData = async () => {
    const response = await Method.GetList(`${server}/api/subject`)
    if(response.status === 200){
      const resData = response.data;
      const dropdown_data = Method.GetDropDownData(resData, 'subject');
      dispatch(setData({ masterData : {subjectList : dropdown_data }, masterDataList : {subjectList : resData}}))
    }else {
      dispatch(setData({ masterData : {subjectList : [] }, masterDataList : {subjectList : []}}))
    }
  };

  // const cycleData = Method.ConvertJsonParse(Method.GetLocalStorage('cycle'))
  const cycleData = Method.GetSessionStorage('cycle')
  const selectedSubjects = cycleData.subject

  useEffect(() => {
    fetchData();
  }, []);
  
  return (
    // <div
    //   style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    // >
    //   <label
    //     style={{ marginBottom: "20px", fontSize: "18px", fontWeight: "bold" }}
    //   >
    //     Which major do you want to pursue?
    //   </label>

    // <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", marginBottom: "20px" }}>
    //   {reduxStore.masterData?.subjectList?.map((o) => (
    //     <div key={o.value} style={{ marginBottom: "10px" }}>
    //       <input
    //         type="checkbox"
    //         id={o.value}
    //         name={o.value}
    //         checked={selectedSubjects?.includes(o.value)}
    //         onChange={() => handleChange('subject', o.value, 'checkbox')}
    //         style={{ marginRight: "10px" }}
    //       />
    //       <label htmlFor={o.value}>{o.label}</label>
    //     </div>
    //   ))}
    // </div>


    //   <div
    //     style={{
    //       display: "flex",
    //       justifyContent: "space-between",
    //       width: "200px",
    //     }}
    //   >
    //     <button
    //       onClick={handlePrevious}
    //       style={{
    //         padding: "10px",
    //         backgroundColor: "#4CAF50",
    //         color: "white",
    //         border: "none",
    //         borderRadius: "5px",
    //         cursor: "pointer",
    //       }}
    //     >
    //       Previous
    //     </button>
    //     <button
    //       onClick={handleNext}
    //       style={{
    //         padding: "10px",
    //         backgroundColor: "#008CBA",
    //         color: "white",
    //         border: "none",
    //         borderRadius: "5px",
    //         cursor: "pointer",
    //       }}
    //     >
    //       Next
    //     </button>
    //   </div>
    // </div>
    <div class="education pd100">
            <div class="container">
                <div class="innerrow">
                    <div class="heading">
                        <h2>Which major do you want to pursue?</h2>
                    </div>
                    <div class="list-education">
                        {reduxStore.masterData?.subjectList?.map((o) => (
                          <div class="left">
                              <input 
                                type="checkbox"
                                name={o.value}
                                checked={selectedSubjects?.includes(o.value)}
                                onChange={() => handleChange('subject', o.value, 'checkbox')}
                              />
                              <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/Circle-icons-image.svg/768px-Circle-icons-image.svg.png" alt=""/>
                              <h3>{o.label}</h3>
                          </div>
                          ))
                        }
                    </div>
                    <div class="btn">
                        <button onClick={handlePrevious} class="main-btn">Previous</button>
                        <button onClick={handleNext} class="main-btn">Next</button>
                    </div>
                </div>
            </div>
        </div>
  );
};

export default Subject;
