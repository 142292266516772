import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import axios from "axios";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { Method } from "../config/method.js";

import { server } from "../config/config";

const Dashboard = () => {
  const [univList, setUnivList] = useState([]);

  const handleUniversitydata = (data) => {

    Method.SetSessionStorage('course', {...data, _id : data.courseid});
  };

  const fetchUnivData = async () => {
    try {
      const cookies = new Cookies(null, { path: "/" });
      const token = cookies.get("jwt");
      const config = {
        headers: {
          Authorization: token ? `Bearer ${token}` : null,
        },
      };
      let filter = {}
      // filter.userid = Method.ConvertJsonParse(Method.GetLocalStorage('userdata'))?._id || "";
      filter.userid = Method.GetSessionStorage('userdata')?._id
      const res = await axios.post(
        `${server}/api/university`,
        { filter },
        config
      );
      setUnivList(res.data.data);
    } catch (err) {
      console.error("Error getting data:", err);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchUnivData();
  }, []);

  return (
    <section>
      <div className="dashboard-section">
        <div className="container">
          <div className="title-wrap">
            <ul className="title">
              <li>University</li>
              <li>Course</li>
              <li>Percentage Filled</li>
              <li>Status</li>
            </ul>
          </div>
          <div className="data-wrap">
            {univList?.map((item, i) => (
              <ul class="data">
                <li>{item.university}</li>
                <li>{item.course} {<NavLink to="/application" onClick={() => handleUniversitydata(item) }>{item.universitychances == 100 ? 'View Application' : 'Complete Your Application'}</NavLink>}</li>
                <li>{item.universitychances}</li>
                {item.universitychances == 100 ? (
                  <li class="status">Completed</li>
                ) : (
                  <li class="status">Pending</li>
                )}
                {/* {item.universitychances !== 100 ? (
                  <NavLink to="/application" onClick={() => handleUniversitydata(item) }>Complete Your Application</NavLink>
                ) : null} */}
                
              </ul>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Dashboard;
