import React from "react";
import { facebook, instgram, linkdin, logo } from "../images";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <footer id="footer">
      <div className="container">
        <div className="foter-top">
          <div className="leftbar">
            <NavLink to="/">
              <img src={logo} alt="" />
            </NavLink>
          </div>
          <div className="righrbar">
            <ul>
              <li>
                <NavLink to="https://webforest.agency/" target="_blank">
                  <img src={facebook} alt="" />
                </NavLink>
              </li>
              <li>
                <NavLink to="https://webforest.agency/" target="_blank">
                  <img src={instgram} alt="" />
                </NavLink>
              </li>
              <li>
                <NavLink to="https://webforest.agency/" target="_blank">
                  <img src={linkdin} alt="" />
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <div id="copyright">
          <p>Copyright &copy; 2024 CourseBuddy </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
