import * as Yup from "yup";

export const educationaldetailsSchema = Yup.object({
  tenthboard: Yup.string().required("Board name is required"),
  tenthyear: Yup.number()
    .required("Passing year is required")
    .integer("Passing year must be a valid integer")
    .min(1900, "Invalid passing year")
    .max(new Date().getFullYear(), "Invalid passing year"),
  tenthpr: Yup.number()
    .required("Percentage is required")
    .min(0, "Percentage must be between 0 and 100")
    .max(100, "Percentage must be between 0 and 100"),

  twelveboard: Yup.string().required("Board name is required"),
  twelveyear: Yup.number()
    .required("Passing year is required")
    .integer("Passing year must be a valid integer")
    .min(1900, "Invalid passing year")
    .max(new Date().getFullYear(), "Invalid passing year"),
  twelvepr: Yup.number()
    .required("Percentage is required")
    .min(0, "Percentage must be between 0 and 100")
    .max(100, "Percentage must be between 0 and 100"),

  graduateuniversity: Yup.string().required("University name is required"), 
  graduateyear: Yup.number()
    .required("Passing year is required")
    .integer("Passing year must be a valid integer")
    .min(1900, "Invalid passing year")
    .max(new Date().getFullYear(), "Invalid passing year"),
  graduatepr: Yup.number()
    .required("Percentage is required")
    .min(0, "Percentage must be between 0 and 100")
    .max(100, "Percentage must be between 0 and 100"),
});
